import React, { useEffect, useReducer, useState } from 'react';
import { Link, useHistory, useLocation, useParams, seParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { toolsUrl } from '../../utils/api';
import { heightAdjust } from '../../utils/utils';

async function getTool(token, toolId) {

    return fetch(`${toolsUrl}/${toolId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getToolData(token, toolId, toolDataId) {

    return fetch(`${toolsUrl}/${toolId}/data/${toolDataId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createToolData(token, toolId, data) {

    return fetch(`${toolsUrl}/${toolId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateToolData(token, toolId, toolDataId, data) {

    return fetch(`${toolsUrl}/${toolId}/data/${toolDataId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delToolData(token, toolId, toolDataId) {

    return fetch(`${toolsUrl}/${toolId}/data/${toolDataId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function ToolData(props) {
    const history = useHistory();
    const location = useLocation();
    let { toolId, toolDataId: id } = useParams();
    const { token } = useToken();

    const [toolName, setToolName] = useState('');
    const [toolDescription, setToolDescription] = useState('');
    const [toolParameters, setToolParameters] = useState('');
    const [toolRequestUrl, setToolRequestUrl] = useState('');
    const [toolRequestOptions, setToolRequestOptions] = useState('');

    const [toolDataId, setToolDataId] = useState(id);
    const [toolDataKey, setToolDataKey] = useState('');
    const [toolDataTags, setToolDataTags] = useState('');
    const [toolDataDescription, setToolDataDescription] = useState('');
    const [toolDataValue, setToolDataValue] = useState('');
    const [toolDataCreated, setToolDataCreated] = useState('');
    const [toolDataModified, setToolDataModified] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (toolId) {
            loadTool();
        }

        if (toolDataId) {
            loadToolData();
        }

        return () => {
            setToolName('');
            setToolDescription('');
            setToolParameters('');
            setToolRequestUrl('');
            setToolRequestOptions('');

            setToolDataKey('');
            setToolDataTags('');
            setToolDataDescription('');
            setToolDataValue('');
            setToolDataCreated('');
            setToolDataModified('');
        }
    }, [token]);

    const loadTool = () => {
        setError();
        getTool(token, toolId).then(r => {
            if (!r.tool || !r.tool.id) {
                console.log(r);
                setError(r);
            }
            setToolName(r.tool.name);
            setToolDescription(r.tool.description);
            setToolParameters(r.tool.parameters);
            setToolRequestUrl(r.tool.request_url);
            setToolRequestOptions(r.tool.request_options);
        });
    };

    const loadToolData = () => {
        setError();
        getToolData(token, toolId, toolDataId).then(r => {
            if (!r.toolData.id) {
                console.log(r);
                setError(r);
            }
            setToolDataKey(r.toolData.key);
            setToolDataTags(r.toolData.tags);
            setToolDataDescription(r.toolData.description);
            setToolDataValue(r.toolData.value);
            setToolDataCreated(r.toolData.created);
            setToolDataModified(r.toolData.Modified);
        });
    };

    const onSubmit = async (redirect, createNew = false) => {
        setError();
        const data = {
            key: toolDataKey,
            tags: toolDataTags,
            description: toolDataDescription,
            value: toolDataValue,
        };
        if (toolDataId) {
            await updateToolData(token, toolId, toolDataId, data);
            loadToolData();
        } else {
            await createToolData(token, toolId, data).then(r => {
                if (!r.toolDataId) {
                    console.log(r);
                    setError(r);
                }
                setToolDataId(r.toolDataId);
            });
        }
        if (redirect) {
            if (createNew) {
                history.push(`/tools/tool/${toolId}?action=createToolData`);
            } else {
                history.push(`/tools/tool/${toolId}`);
            }
        }
    };

    const removeToolData = (toolDataId) => {
        if (window.confirm("Do you really want to delete this data?")) {
            delToolData(token, toolId, toolDataId).then(r => {
                // console.log(r);
                history.push(`/tools/tool/${toolId}`);
            });
        }
    };

    const toolDataTitle = toolDataId ? `Edit Data #${toolDataId}` : 'Add New Data';

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/tools">Tools</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/tools/tool/${toolId}`}>Tool "{toolName}"</Breadcrumb.Item>
                <Breadcrumb.Item active>{toolDataKey}</Breadcrumb.Item>
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <Alert variant="primary">
                <Form>
                    <h2 className="my-2 text-center">{toolDataKey}</h2>
                    <fieldset>
                        <Row className="align-items-center" key={`created`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Added</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label>{toolDataCreated ? new Date(toolDataCreated).toLocaleString('en-US') : ''}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="align-items-center" key={`modified`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Modified</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label>{toolDataModified ? new Date(toolDataModified).toLocaleString('en-US') : ''}</Form.Label>
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <Row className="align-items-center" key={`key`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Key *</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    placeholder={`Enter Key`}
                                    value={toolDataKey}
                                    onChange={e => setToolDataKey(e.target.value)}
                                    className="mb-2"
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <Row className="align-items-center" key={`tags`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Tags</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    placeholder={`Enter Tags`}
                                    value={toolDataTags}
                                    onChange={e => setToolDataTags(e.target.value)}
                                    className="mb-2"
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <Row className="align-items-center" key={`description`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Description</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    placeholder={`Enter Description`}
                                    value={toolDataDescription}
                                    onChange={e => setToolDataDescription(e.target.value)}
                                    className="mb-2"
                                    onKeyUp={e => heightAdjust(e.target)}
                                    style={{ overflow: 'hidden', height: '100px' }}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <Row className="align-items-center" key={`value`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Value *</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    placeholder={`Enter Value`}
                                    value={toolDataValue}
                                    onChange={e => setToolDataValue(e.target.value)}
                                    className="mb-2"
                                    onKeyUp={e => heightAdjust(e.target)}
                                    style={{ overflow: 'hidden', height: '200px' }}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <div className="d-flex">
                        <Link to={`/tools/tool/${toolId}`}>
                            <Button
                                variant="secondary"
                                className="me-1 my-1"
                                size="sm"
                            >
                                &lt; Close
                            </Button>
                        </Link>
                        {toolDataId ? (
                            <Button
                                variant="danger"
                                className="me-1 my-1 ms-auto"
                                onClick={e => removeToolData(toolDataId)}
                                size="sm"
                            >
                                Remove
                            </Button>
                        ) : ''}
                        <Button
                            variant="primary"
                            className="me-1 my-1 ms-auto"
                            onClick={e => onSubmit(true, false)}
                            size="sm"
                        >
                            &lt; Save and Close
                        </Button>
                        <Button
                            variant="primary"
                            className="mx-1 my-1"
                            onClick={e => onSubmit(false, false)}
                            size="sm"
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="success"
                            className="ms-1 my-1"
                            onClick={e => onSubmit(true, true)}
                            size="sm"
                        >
                            Save and Add New &gt;
                        </Button>
                    </div>
                </Form>
            </Alert>
        </>);
}