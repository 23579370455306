import { useState } from 'react';
import useToken from './useToken';

export default function useSettings() {
    const getSettings = () => {
        const settingsString = localStorage.getItem('settings');
        const settings = JSON.parse(settingsString);

        return settings || {
            // wordsCount: 1,
            // scrollingSpeed: 3, // 1, 2, 3, 4, 5
            // fontSize: 32, // 16 - 64
            // textColor: '#ffffff',
            // backgroundColor: '#000000',
            // flashMode: false,
            // vertical: false,
            // sound: 'ES_PREL Hit Digital 18 - SFX Producer.mp3',
            // items: 25, // 25 - 100
        };
    };

    const [settings, setSettings] = useState(getSettings());
    const { token } = useToken();

    const saveSettings = settings => {
        localStorage.setItem('settings', JSON.stringify(settings));
        setSettings(settings);
    };

    return {
        setSettings: saveSettings,
        settings,
    };
}