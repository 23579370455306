import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { toolsUrl } from '../../utils/api';
import { heightAdjust, jsonString } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { gpt3Encode, gpt3Decode } from '../GPT3/Encoder';

async function getTool(token, toolId) {

    return fetch(`${toolsUrl}/${toolId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function playTool(token, toolId, params) {

    return fetch(`${toolsUrl}/${toolId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            key: params,
        }),
    })
        .then(data => data.json());
}

async function createToolData(token, toolId, data) {

    return fetch(`${toolsUrl}/${toolId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateToolData(token, toolId, data, toolDataId) {

    return fetch(`${toolsUrl}/${toolId}/data/${toolDataId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

export default function ToolPlay(props) {
    let {
        toolId: id,
    } = useParams();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [toolId, setToolId] = useState(id);
    const [toolTitle, setToolTitle] = useState('');
    const [toolName, setToolName] = useState('');
    const [toolDecsription, setToolDescription] = useState('');
    const [toolParameters, setToolParameters] = useState('');
    const [toolUrl, setToolUrl] = useState('');
    const [toolOptions, setToolOptions] = useState('');
    const { settings, setSettings } = useSettings();
    const [error, setError] = useState(null);

    const [params, setParams] = useState('');
    const [result, setResult] = useState('');

    const [toolDataKey, setToolDataKey] = useState('');
    const [toolDataTags, setToolDataTags] = useState('');
    const [toolDataDescription, setToolDataDescription] = useState('');
    const [toolDataValue, setToolDataValue] = useState('');

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (id) {
            setToolId(id);
            loadTool();
        }

        return () => {
            setToolTitle('');
            setToolName('');
            setToolDescription('');
            setToolParameters('');
            setToolUrl('');
            setToolOptions('');

            setToolDataKey('');
            setToolDataTags('');
            setToolDataDescription('');
            setToolDataValue('');

            setParams('');
            setResult('');
        }
    }, [token]);

    const loadTool = async () => {
        setError();
        await getTool(token, toolId).then(async r => {
            if (!r.tool || !r.tool.id) {
                console.log(r);
                setError(r);
            } else {
                setToolTitle(r.tool.title);
                setToolName(r.tool.name);
                setToolDescription(r.tool.description);
                setToolParameters(r.tool.parameters);
                setToolUrl(r.tool.request_url);
                setToolOptions(r.tool.request_options);
            }
        });

    };

    const callTool = () => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;
        playTool(token, toolId, params).then(r => {
            if (typeof r.result === 'undefined') {
                console.log(r);
                setError(r);
            }
            setParams('');
            goButton.disabled = false;
            setResult(r.result);
            console.log(r.result);
        });
    };

    const toolPageTitle = `Tool "${toolTitle || toolName}"`;

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/tools">Tools</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/tools/tool/${toolId}`}>{toolPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item active>Sandbox</Breadcrumb.Item>
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {
                        `${error.error ?
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            ) : (error)
                        }`
                    }
                </Alert>
            )}

            <h2 className="my-2 text-center" key="pageTitle">
                {toolPageTitle}
            </h2>

            <Modal show={showPopup} onHide={e => {
                setShowPopup(false);
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Data Added</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The data has been successfully added to the current Tool
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => {
                        setShowPopup(false);
                    }}>
                        OK, go ahead
                    </Button>
                </Modal.Footer>
            </Modal>

            {toolId ? (
                <>
                    <Alert key={`pair-new`} variant="primary">
                        <Form>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Key"
                                className="mb-2"
                                value={params}
                                onChange={(e) => setParams(e.target.value)}
                                onKeyUp={e => heightAdjust(e.target)}
                                style={{ overflow: 'hidden', height: '200px' }}
                            />
                            <Button id='goButton' type="button" onClick={(e) => {
                                callTool();
                            }}>Go</Button>
                        </Form>
                    </Alert>
                    <>
                        {result ?
                            <Alert key={`pair-new`} variant="primary">
                                {result && result.length ? result.map(item => <Alert><h4>{item.key}</h4><p>{item.value}</p></Alert>) : ''}
                            </Alert> : ''}
                    </>
                </>
            ) : ''}
        </>);
}