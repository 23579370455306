import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { flowsUrl, modelsUrl } from '../../utils/api';
import { heightAdjust } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { gpt3Encode, gpt3Decode } from '../GPT3/Encoder';

async function getFlow(token, flowId) {

    return fetch(`${flowsUrl}/${flowId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function playFlow(token, flowId, prompt) {

    return fetch(`${flowsUrl}/${flowId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            prompt: prompt,
        }),
    })
        .then(data => data.json());
}

async function playModel(token, modelId, prompt, modelVersion, flowId = null) {

    return fetch(`${modelsUrl}/${modelId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            flow_id: flowId,
            prompt: prompt,
            model_version_id: modelVersion,
        }),
    })
        .then(data => data.json());
}

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createModelData(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function getModelHistoryList(token, modelId, flowId) {

    return fetch(`${modelsUrl}/${modelId}/history?flowId=${flowId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getHistory(token, modelId, historyId) {

    return fetch(`${modelsUrl}/${modelId}/history/${historyId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

// TODO

export default function FlowPlay(props) {
    let { flowId: id } = useParams();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const { settings, setSettings } = useSettings();

    const [flowId, setFlowId] = useState(id);
    const [flowTitle, setFlowTitle] = useState('');
    const [flowModels, setFlowModels] = useState([]);

    const [modelKey, setModelKey] = useState(0);
    const [modelId, setModelId] = useState();
    const [modelTitle, setModelTitle] = useState('');
    const [modelPrompt, setModelPrompt] = useState([]);
    const [modelCompletion, setModelCompletion] = useState([]);

    const [prompt, setPrompt] = useState('');
    const [modelHistoryList, setModelHistoryList] = useState([]);
    const [historyId, setHistoryId] = useState(null);
    const [history, setHistory] = useState({});

    const [modelDataPrompt, setModelDataPrompt] = useState([]);
    const [modelDataCompletion, setModelDataCompletion] = useState([]);

    const [step, setStep] = useState(0);
    const [stepPrompt, setStepPrompt] = useState();

    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            setFlowId(id);
            loadFlow();
        }

        return () => {
            setFlowTitle('');
            setFlowModels([]);

            setModelTitle('');
            setModelPrompt([]);
            setModelCompletion([]);
            setModelDataPrompt([]);
            setModelDataCompletion([]);
            setModelHistoryList([]);
        }
    }, [token]);

    useEffect(() => {
        if (modelId) {
            loadModel();
            loadModelHistoryList();
        }

        return () => {
            setModelTitle('');
            setModelPrompt([]);
            setModelCompletion([]);
            setModelDataPrompt([]);
            setModelDataCompletion([]);
            setModelHistoryList([]);
        }
    }, [modelId]);

    useEffect(() => {
        if (step) {
            getFlowCompletion();
        }

        return () => {
            setStep(0);
        }
    }, [step]);

    const loadFlow = () => {
        // setError();
        getFlow(token, flowId).then(r => {
            if (!r.flow || !r.flow.id) {
                console.log(r);
                setError(r);
            }
            const rModels = JSON.parse(r.flow.models || []);
            setFlowTitle(r.flow.title);
            if (rModels.length) {
                setFlowModels(rModels);
                setModelId(rModels[0]);
            }
        });
    };

    const loadModel = () => {
        setError();
        getModel(token, modelId).then(r => {
            if (!r.model || !r.model.id) {
                console.log(r);
                setError(r);
            }
            setModelTitle(r.model.title);
            setModelPrompt(JSON.parse(r.model.prompt || []));
            setModelCompletion(JSON.parse(r.model.completion || []));
        });
    };

    const loadModelHistoryList = (cModelId = null) => {
        getModelHistoryList(token, cModelId || modelId, flowId).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setModelHistoryList(r.list || []);
        });
    };

    const getFlowCompletion = (cPrompt = null) => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;

        setHistory([]);

        if (step >= flowModels.length) {
            goButton.disabled = false;
            setModelKey(0);
            setModelId(flowModels[0]);
        } else {
            setModelKey(step);
            setModelId(flowModels[step]);
        }

        playModel(token, flowModels[step], cPrompt || stepPrompt || prompt, null, flowId).then(r => {
            if (!r.completion && !r.prompt) {
                console.log(r);
                setError(r);
            }
            setPrompt('');
            goButton.disabled = false;
            loadModelHistoryList(flowModels[step]);
        });
    };

    const getHistoryData = (historyId) => {
        getHistory(token, modelId, historyId).then(r => {
            if (!r.history) {
                console.log(r);
                setError(r);
            }
            setHistory(r.history || []);
            setModelDataPrompt(r.history.parsed_prompt);
            setModelDataCompletion(r.history.parsed_completion);
            setHistoryId(historyId);
        });
    };

    // const onSubmit = async () => {
    //     setError();
    //     const data = {
    //         prompt: JSON.stringify(modelDataPrompt),
    //         completion: JSON.stringify(modelDataCompletion),
    //     };

    //     await createModelData(token, modelId, data).then(r => {
    //         if (!r.modelDataId) {
    //             console.log(r);
    //             setError(r);
    //         }

    //         setHistoryId();
    //     });
    // };

    const onSaveAndContinue = (historyId) => {
        getHistory(token, modelId, historyId).then(r => {
            if (!r.history) {
                console.log(r);
                setError(r);
            }
            const data = {
                prompt: JSON.stringify(r.history.parsed_prompt),
                completion: JSON.stringify(r.history.parsed_completion),
            };

            setStepPrompt(r.history.parsed_completion[0]);

            createModelData(token, modelId, data).then(r => {
                if (!r.modelDataId) {
                    console.log(r);
                    setError(r);
                }
            });

            if (step < flowModels.length) {
                setStep(step + 1);
            }
        });
    };

    const onContinueWithoutSaving = (historyId) => {
        getHistory(token, modelId, historyId).then(r => {
            if (!r.history) {
                console.log(r);
                setError(r);
            }
            const data = {
                prompt: JSON.stringify(r.history.parsed_prompt),
                completion: JSON.stringify(r.history.parsed_completion),
            };

            setStepPrompt(r.history.parsed_completion[0]);

            if (step < flowModels.length) {
                setStep(step + 1);
            }
        });
    };

    const onSaveChangesAndContinue = () => {
        setError();
        const data = {
            prompt: JSON.stringify(modelDataPrompt),
            completion: JSON.stringify(modelDataCompletion),
        };

        setStepPrompt(modelDataCompletion[0]);

        createModelData(token, modelId, data).then(r => {
            if (!r.modelDataId) {
                console.log(r);
                setError(r);
            }

            setHistoryId();
        });

        if (step < flowModels.length) {
            setStep(step + 1);
        }
    };

    const onSaveChanges = () => {
        setError();
        const data = {
            prompt: JSON.stringify(modelDataPrompt),
            completion: JSON.stringify(modelDataCompletion),
        };

        setStepPrompt();
        setPrompt();

        createModelData(token, modelId, data).then(r => {
            if (!r.modelDataId) {
                console.log(r);
                setError(r);
            }

            setHistoryId();
        });
    };

    const prepareDecode = (encoded) => {
        const result = [];
        for (let token of encoded) {
            result.push({ token, string: gpt3Decode([token]) });
        }
        return result;
    }

    const flowPageTitle = `Flow "${flowTitle}"`;
    const modelPageTitle = `Model "${modelTitle}"`;

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item href="#/flows">Flows</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/flows/flow/${flowId}`}>{flowPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item active>Sandbox</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {
                        `${error.error ?
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            ) : (error)
                        }`
                    }
                </Alert>
            )}

            <h2 className="my-2 text-center" key="pageTitle">
                {flowPageTitle}
            </h2>

            <Form>
                <Alert key={`pair-new`}>
                    <Form.Control
                        as="textarea"
                        placeholder="Enter prompt"
                        className="mb-2"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyUp={e => heightAdjust(e.target)}
                        style={{ overflow: 'hidden', height: '100px' }}
                    />
                    <Button id='goButton' type="button" onClick={(e) => {
                        setStepPrompt(prompt);
                        setModelKey(0);
                        setModelId(flowModels[0]);
                        getFlowCompletion(prompt);
                    }}>Go Step by Step</Button>
                </Alert>
            </Form>

            <Tabs
                id="modelHistoryTabs"
                activeKey={modelKey}
                onSelect={k => {
                    setModelKey(k);
                    setModelId(flowModels[k]);
                }}
            >
                {flowModels && flowModels.length ? (
                    flowModels.map((flowModelId, modelIndex) => {
                        return (
                            <Tab eventKey={modelIndex} title={`Step ${modelIndex + 1}`} key={modelIndex}>
                                <h2 className="my-2 text-center">
                                    {modelPageTitle}
                                </h2>
                            </Tab>
                        );
                    })
                ) : ''}
            </Tabs>

            {modelId && modelHistoryList ? (
                <>
                    {modelHistoryList.map(pair =>
                        pair && pair.id ?
                            (historyId == pair.id ? (
                                <Alert key={`pair-${pair.id}`}>
                                    <Form>
                                        <fieldset>
                                            <legend>Prompt</legend>
                                            {modelPrompt.map((prompt, index) =>
                                                <Row className="align-items-center" key={`prompt-${index}`}>
                                                    <Col className="text-end" sm="2">
                                                        <Form.Label>{prompt} *</Form.Label>
                                                    </Col>
                                                    <Col sm="10">
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder={`Enter ${prompt}`}
                                                            value={modelDataPrompt[index]}
                                                            onChange={e => setModelDataPrompt(modelPrompt.map((v, i) => i == index ? e.target.value : modelDataPrompt[i]))}
                                                            className="mb-2"
                                                            onKeyUp={e => heightAdjust(e.target)}
                                                            style={{ overflow: 'hidden', height: '100px' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        <fieldset>
                                            <legend>Completion</legend>
                                            {modelCompletion.map((completion, index) =>
                                                <Row className="align-items-center" key={`completion-${index}`}>
                                                    <Col className="text-end" sm="2">
                                                        <Form.Label>{completion} *</Form.Label>
                                                    </Col>
                                                    <Col sm="10">
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder={`Enter ${completion}`}
                                                            value={modelDataCompletion[index]}
                                                            onChange={e => setModelDataCompletion(modelCompletion.map((v, i) => i == index ? e.target.value : modelDataCompletion[i]))}
                                                            className="mb-2"
                                                            onKeyUp={e => heightAdjust(e.target)}
                                                            style={{ overflow: 'hidden', height: '100px' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        <div className="d-flex">
                                            <Button
                                                variant="secondary"
                                                className="me-auto my-1"
                                                size="sm"
                                                onClick={
                                                    (e) => {
                                                        setHistoryId(null);
                                                    }
                                                }
                                            >
                                                &lt; Close
                                            </Button>
                                            {(flowModels.length > modelKey + 1) ? (
                                                <Button
                                                    variant="success"
                                                    className="ms-auto my-1"
                                                    onClick={onSaveChangesAndContinue}
                                                    size="sm"
                                                >
                                                    Save and Continue &gt;
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="success"
                                                    className="ms-auto my-1"
                                                    onClick={onSaveChanges}
                                                    size="sm"
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </Alert>
                            ) : (
                                <Alert key={`pair-${pair.id}`}>
                                    <pre style={{ whiteSpace: 'pre-wrap' }}>{pair.prompt}</pre>
                                    <hr></hr>
                                    <pre style={{ whiteSpace: 'pre-wrap' }} className="ms-5">{pair.completion}</pre>
                                    <hr></hr>
                                    <div className="d-flex">
                                        <Button
                                            variant="outline-primary"
                                            className="me-1 my-1"
                                            size="sm"
                                            onClick={
                                                (e) => {
                                                    getHistoryData(pair.id);
                                                }
                                            }
                                        >
                                            Edit &gt;
                                        </Button>
                                        {(flowModels.length > modelKey + 1) ? (
                                            <>
                                                <Button
                                                    variant="outline-primary"
                                                    className="mx-1 my-1"
                                                    size="sm"
                                                    onClick={
                                                        (e) => {
                                                            onSaveAndContinue(pair.id);
                                                        }
                                                    }
                                                >
                                                    Save and Continue &gt;
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    className="me-auto ms-1 my-1"
                                                    size="sm"
                                                    onClick={
                                                        (e) => {
                                                            onContinueWithoutSaving(pair.id);
                                                        }
                                                    }
                                                >
                                                    Continue without Saving &gt;
                                                </Button>
                                            </>
                                        ) : ''}
                                        <span
                                            className="ms-auto my-1"
                                        >
                                            <small>{pair.created}</small>
                                        </span>
                                    </div>
                                </Alert>
                            )
                            ) : ''
                    )}
                </>
            ) : ''
            }

            {/* {modelId && modelPlayList ? (
                <>
                    {modelPlayList.map((pair, index) =>
                        pair && pair.completion ?
                            (index == modelPlayList.length - 1 ?
                                (
                                    <Alert key={`pair-${index}`}>
                                        <Form>
                                            <fieldset>
                                                <legend>Prompt</legend>
                                                {modelPrompt.map((prompt, index) =>
                                                    <Row className="align-items-center" key={`prompt-${index}`}>
                                                        <Col className="text-end" sm="2">
                                                            <Form.Label>{prompt} *</Form.Label>
                                                        </Col>
                                                        <Col sm="10">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows="4"
                                                                placeholder={`Enter ${prompt}`}
                                                                value={modelDataPrompt[index]}
                                                                onChange={e => setModelDataPrompt(modelPrompt.map((v, i) => i == index ? e.target.value : modelDataPrompt[i]))}
                                                                className="mb-2"
                                                                disabled={!modelDataEnabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </fieldset>
                                            <fieldset>
                                                <legend>Completion</legend>
                                                {modelCompletion.map((completion, index) =>
                                                    <Row className="align-items-center" key={`completion-${index}`}>
                                                        <Col className="text-end" sm="2">
                                                            <Form.Label>{completion} *</Form.Label>
                                                        </Col>
                                                        <Col sm="10">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows="3"
                                                                placeholder={`Enter ${completion}`}
                                                                value={modelDataCompletion[index]}
                                                                onChange={e => setModelDataCompletion(modelCompletion.map((v, i) => i == index ? e.target.value : modelDataCompletion[i]))}
                                                                className="mb-2"
                                                                disabled={!modelDataEnabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </fieldset>
                                            {modelDataEnabled ? (
                                                <div className="d-flex">
                                                    <Button
                                                        variant="secondary"
                                                        className="me-1 my-1"
                                                        size="sm"
                                                        onClick={e => {
                                                            setModelDataEnabled(false);
                                                        }}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        className="ms-auto my-1"
                                                        onClick={onSubmit}
                                                        size="sm"
                                                    >
                                                        Save as Model Data
                                                    </Button>
                                                </div>
                                            ) : (modelDataSaved ? (
                                                <Button
                                                    variant="success"
                                                    className="mx-1 my-1"
                                                    size="sm"
                                                    disabled
                                                >
                                                    Saved as Model Data #{modelDataSaved}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="success"
                                                    className="me-1 my-1"
                                                    onClick={e => {
                                                        setModelDataEnabled(true);
                                                    }}
                                                    size="sm"
                                                >
                                                    Add as Model Data
                                                </Button>
                                            ))}
                                        </Form>
                                    </Alert>
                                ) : (
                                    <Alert key={`pair-${index}`}>
                                        <pre style={{ whiteSpace: 'pre-wrap' }}>{pair.prompt}</pre>
                                        <hr></hr>
                                        <pre style={{ whiteSpace: 'pre-wrap' }}>{pair.completion}</pre>
                                    </Alert>
                                )
                            ) : ''
                    )}
                    <Form>
                        <Alert key={`pair-new`}>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                placeholder="Enter prompt"
                                className="mb-2"
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                            />
                            <Button id='goButton' type="button" onClick={(e) => {
                                getCompletion();
                            }}>Go</Button>
                        </Alert>
                    </Form>
                </>
            ) : ''
            } */}
        </>);
}