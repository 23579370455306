import React, { useEffect, useState } from 'react';
import { HashRouter, Link, Redirect, Route, Switch } from 'react-router-dom';

import { Button, Container, Navbar, Nav, Offcanvas } from 'react-bootstrap';
// import { faCog, faBookOpen, faSignOutAlt, faSignInAlt, faChartLine, faUserPlus } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useToken from './useToken';
import useUser from './useUser';

// Screens
import Register from '../Register/Register';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import Paths from '../Paths/Paths';
import Path from '../Path/Path';
import PathDrop from '../Path_drop/PathDrop';
import PathPlay from '../PathPlay/PathPlay';
import Flows from '../Flows/Flows';
import Flow from '../Flow/Flow';
import FlowPlay from '../FlowPlay/FlowPlay';
import Models from '../Models/Models';
import Model from '../Model/Model';
import ModelPlay from '../ModelPlay/ModelPlay';
import ModelData from '../ModelData/ModelData';
import Tools from '../Tools/Tools';
import Tool from '../Tool/Tool';
import ToolPlay from '../ToolPlay/ToolPlay';
import ToolData from '../ToolData/ToolData';
import ApiKeys from '../ApiKeys/ApiKeys';
import Billing from '../Billing/Statistics';
import Companies from '../Companies/List';
import Users from '../Users/List';
import Sandbox from '../Sandbox/Sandbox';

import { inIframe } from '../../utils/utils';

// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import MyAccount from '../MyAccount';
// import 'react-tagsinput/react-tagsinput.css'

function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  // const [colorScheme, setColorScheme] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark');
  const [colorScheme, setColorScheme] = useState('dark');

  useEffect(() => {
    if (inIframe()) {
      setTimeout(() => {
        window.addEventListener("message", (event) => {
          if (event.data) {
            switch (event.data) {
              case 'dashboard':
                document.getElementById('linkDashboard').click();
                break;
              case 'paths':
                document.getElementById('linkPaths').click();
                break;
              case 'flows':
                document.getElementById('linkFlows').click();
                break;
              case 'models':
                document.getElementById('linkModels').click();
                break;
              case 'tools':
                document.getElementById('linkTools').click();
                break;
              case 'billing':
                document.getElementById('linkBilling').click();
                break;
              case 'api':
                document.getElementById('linkApi').click();
                break;
              case 'account':
                document.getElementById('linkAccount').click();
                break;
              case 'logout':
                window.parent.postMessage('sLogout', 'https://strietzel.ai');
                setToken({ token: null });
                setUser({});
                const linkLogout = document.getElementById('linkLogout');
                if (linkLogout) linkLogout.click();
                break;
              default:
                const r = JSON.parse(event.data) || event.data;
                if (r && r.action == 'login') {
                  setToken(r.auth || null);
                  setUser(r.user || null);
                }
            }
          }
        });
      }, 0);
    }

    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    //   setColorScheme(e.matches ? "dark" : "light");
    // });
  }, []);

  const currentTime = new Date();

  if (!token || !user || !user.id) {

    return (
      <HashRouter>
        <Navbar expand="md" fixed="top" className="shadow-sm" bg={colorScheme} variant={colorScheme} style={inIframe() ? { visibility: 'hidden' } : {}}>
          <Container>
            <Navbar.Brand href="/">
              {/* <img src="/logo-black-hires.png" height="32" id="logoImg" /> */}
              {/* <img src="//cdn.shopify.com/s/files/1/0617/7076/0379/files/logo-white-hires_1_300x300.png" height="32" id="logoImg" /> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <i class="fa-solid fa-burger"></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Link to="/login" className="nav-link link-primary text-primary">
                  {/* <FontAwesomeIcon icon={faSignInAlt} fixedWidth /> */}
                  Sign In
                </Link>
                <Link to="/register" className="nav-link link-success text-success">
                  {/* <FontAwesomeIcon icon={faUserPlus} fixedWidth /> */}
                  Sign Up
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="my-4 py-5">
          <Switch>
            <Route path="/login">
              <Login setToken={setToken} setUser={setUser} />
            </Route>
            <Route path="/register">
              <Register setToken={setToken} setUser={setUser} />
            </Route>
            <Route path="/">
              <Login setToken={setToken} setUser={setUser} />
            </Route>
          </Switch>
        </Container>
        <Navbar expand="md" fixed="bottom" className="shadow-sm" bg={colorScheme} variant={colorScheme}>
          <Container>
            <Navbar.Text>
              <small>© Strietzel 2022-{currentTime.getFullYear()}</small>
            </Navbar.Text>
          </Container>
        </Navbar>
      </HashRouter>
    );
  }

  return (
    <HashRouter>
      <Navbar expand="md" fixed="top" className="shadow-sm" collapseOnSelect
        // style={inIframe() ? { visibility: 'hidden' } : {}}
        bg={colorScheme} variant={colorScheme}>
        <Container>
          <Navbar.Brand href="/" id="linkUser" onClick={e => {
            e.preventDefault();
            handleShowOffcanvas();
          }} >
            {/* <img src="/logo-black-hires.png" height="32" id="logoImg" /> */}
            {/* <img src="//cdn.shopify.com/s/files/1/0617/7076/0379/files/logo-white-hires_1_300x300.png" height="32" id="logoImg" /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#/dashboard" className="nav-link" id="linkDashboard">
                Dashboard
              </Nav.Link>
              <Nav.Link href="#/paths" className="nav-link" id="linkPaths">
                Paths
              </Nav.Link>
              <Nav.Link href="#/flows" className="nav-link" id="linkFlows">
                Flows
              </Nav.Link>
              <Nav.Link href="#/models" className="nav-link" id="linkModels">
                Models
              </Nav.Link>
              <Nav.Link href="#/tools" className="nav-link" id="linkTools">
                Functions
              </Nav.Link>
              <Nav.Link href="#/sandbox" className="nav-link" id="linkSandbox">
                Sandbox
              </Nav.Link>
              <Nav.Link href="#/companies" className="nav-link" id="linkModels">
                Companies
              </Nav.Link>
              <Nav.Link href="#/users" className="nav-link" id="linkModels">
                Users
              </Nav.Link>
              <Nav.Link href="#/billing" className="nav-link" id="linkBilling">
                Billing
              </Nav.Link>
              <Nav.Link href="#/api" className="nav-link" id="linkApi">
                API
              </Nav.Link>
              <Nav.Link href="#/account" className="nav-link" id="linkAccount" onClick={() => window.parent.postMessage('sAccount', 'https://strietzel.ai')}>
                My Account
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link
                // style={inIframe() ? { visibility: 'hidden' } : {}}
                href="/"
                onClick={e => {
                  window.parent.postMessage('sLogout', 'https://strietzel.ai');
                  setToken({ token: null });
                  setUser({});
                }}
                id="linkLogout"
              >
                {/* <FontAwesomeIcon icon={faSignOutAlt} fixedWidth /> */}
                Log out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas}>
        <Offcanvas.Header closeButton onClick={handleCloseOffcanvas} style={{ padding: '1.5rem 1.5rem' }} className="mt-5">
          <Offcanvas.Title>
            {/* <img src="/logo-black-hires.png" height="24" /> */}
            <img src="//cdn.shopify.com/s/files/1/0617/7076/0379/files/logo-white-hires_1_300x300.png" height="32" id="logoImgOffcanvas" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: '1.5rem 1.5rem' }}>
          <Navbar.Text><strong>USER</strong></Navbar.Text>
          <Nav.Link href="#/dashboard" onClick={handleCloseOffcanvas}>Dashboard</Nav.Link>
          <Nav.Link href="#/paths" onClick={handleCloseOffcanvas}>Paths</Nav.Link>
          <Nav.Link href="#/flows" onClick={handleCloseOffcanvas}>Flows</Nav.Link>
          <Nav.Link href="#/models" onClick={handleCloseOffcanvas}>Models</Nav.Link>
          <Nav.Link href="#/tools" onClick={handleCloseOffcanvas}>Functions</Nav.Link>
          <Nav.Link href="#/sandbox" onClick={handleCloseOffcanvas}>Sandbox</Nav.Link>
          <Nav.Link href="#/companies" onClick={handleCloseOffcanvas}>Companies</Nav.Link>
          <Nav.Link href="#/users" onClick={handleCloseOffcanvas}>Users</Nav.Link>
          <Nav.Link href="#/billing" onClick={handleCloseOffcanvas}>Billing</Nav.Link>
          <Nav.Link href="#/api" onClick={handleCloseOffcanvas}>API</Nav.Link>
          <Nav.Link href="#/account" onClick={handleCloseOffcanvas}>My Account</Nav.Link>
          <Nav.Link
            href="/"
            onClick={e => {
              setToken({ token: null });
              setUser({});
            }}
            id="linkLogout"
          >Log out</Nav.Link>
        </Offcanvas.Body>
      </Offcanvas>
      <Switch>
        <Route path="/models/model/:modelId/data/:modelDataId">
          <Container className="my-4 py-5">
            <ModelData />
          </Container>
        </Route>
        <Route path="/models/model/:modelId/sandbox">
          <Container className="my-4 py-5">
            <ModelPlay />
          </Container>
        </Route>
        <Route path="/models/model/:modelId/data">
          <Container className="my-4 py-5">
            <ModelData />
          </Container>
        </Route>
        <Route path="/models/model/:modelId">
          <Container className="my-4 py-5">
            <Model />
          </Container>
        </Route>
        <Route path="/models/model">
          <Container className="my-4 py-5">
            <Model />
          </Container>
        </Route>
        <Route path="/models">
          <Container className="my-4 py-5">
            <Models />
          </Container>
        </Route>
        <Route path="/tools/tool/:toolId/data/:toolDataId">
          <Container className="my-4 py-5">
            <ToolData />
          </Container>
        </Route>
        <Route path="/tools/tool/:toolId/sandbox">
          <Container className="my-4 py-5">
            <ToolPlay />
          </Container>
        </Route>
        <Route path="/tools/tool/:toolId/data">
          <Container className="my-4 py-5">
            <ToolData />
          </Container>
        </Route>
        <Route path="/tools/tool/:toolId">
          <Container className="my-4 py-5">
            <Tool />
          </Container>
        </Route>
        <Route path="/tools/tool">
          <Container className="my-4 py-5">
            <Tool />
          </Container>
        </Route>
        <Route path="/tools">
          <Container className="my-4 py-5">
            <Tools />
          </Container>
        </Route>
        <Route path="/sandbox">
          <Container className="my-4 py-5">
            <Sandbox />
          </Container>
        </Route>
        <Route path="/paths/path/:pathId/sandbox">
          <Container className="my-4 py-5">
            <PathPlay />
          </Container>
        </Route>
        {/* <Route path="/paths/path/:pathId">
          <Container className="my-4 py-5">
            <Path />
          </Container>
        </Route> */}
        <Route path="/paths/path/:pathId">
          <Container className="my-4 py-5">
            <PathDrop />
          </Container>
        </Route>
        <Route path="/paths/path">
          <Container className="my-4 py-5">
            <Path />
          </Container>
        </Route>
        <Route path="/paths">
          <Container className="my-4 py-5">
            <Paths />
          </Container>
        </Route>

        <Route path="/companies">
          <Container className="my-4 py-5">
            <Companies />
          </Container>
        </Route>

        <Route path="/users">
          <Container className="my-4 py-5">
            <Users />
          </Container>
        </Route>

        <Route path="/flows/flow/:flowId/sandbox">
          <Container className="my-4 py-5">
            <FlowPlay />
          </Container>
        </Route>
        <Route path="/flows/flow/:flowId">
          <Container className="my-4 py-5">
            <Flow />
          </Container>
        </Route>
        <Route path="/flows/flow">
          <Container className="my-4 py-5">
            <Flow />
          </Container>
        </Route>
        <Route path="/flows">
          <Container className="my-4 py-5">
            <Flows />
          </Container>
        </Route>
        <Route path="/dashboard">
          <Container className="my-4 py-5">
            <Dashboard />
          </Container>
        </Route>
        <Route path="/api">
          <Container className="my-4 py-5">
            <ApiKeys />
          </Container>
        </Route>
        <Route path="/billing/:customerId">
          <Container className="my-4 py-5">
            <Billing />
          </Container>
        </Route>
        <Route path="/billing">
          <Container className="my-4 py-5">
            <Billing />
          </Container>
        </Route>
        <Route path="/account">
          <Container className="my-4 py-5">
            <MyAccount />
          </Container>
        </Route>
        <Route path="/">
          <Container className="my-4 py-5">
            <Dashboard />
          </Container>
        </Route>
      </Switch>
      <Navbar expand="md" fixed="bottom" className="shadow-sm" bg={colorScheme} variant={colorScheme}>
        <Container>
          <Navbar.Text>
            <small>© Strietzel 2022-{currentTime.getFullYear()}</small>
          </Navbar.Text>
        </Container>
      </Navbar>
    </HashRouter>
  );
}

export default App;