import isEqual from 'lodash.isequal';

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function jsonString(str) {
  let result = false;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return result;
}

export function heightAdjust(target) {
  target.style.height = "80px";
  target.style.height = 25 + target.scrollHeight + "px";
}

function recursChange(node, depth, name, chosenModel, type) {
  try {
    if (node.name === name && depth === 0) {
      if (type === "delete") {
        node.attributes.title = "";
        node.id = null;
        node.children = [];
      }
      if (type === "addNewModal") {
        node.attributes.title = chosenModel.title;
        node.id = chosenModel.id;
        if (chosenModel.classification) {
          const classific = JSON.parse(chosenModel.classification);
          node.children = classific.map((cl) => ({ name: cl, children: [], attributes: {}, id: null }));
        }
      }

      if (type === "modify") {
        if (chosenModel.title) {
          node.attributes.title = chosenModel.title;
        }

        if (chosenModel.classification) {
          const classific = JSON.parse(chosenModel.classification);
          node.children = classific.map((cl) => ({ name: cl, children: [], attributes: {}, id: null }));
        }
      }
    }
    if (depth > 0 && node.children) {
      node.children.forEach((child) => {
        recursChange(child, depth - 1, name, chosenModel, type);
      });
    }
  } catch (error) {
    console.log(error);
  }
}
export function CoverRecurs(oldTree, depth, name, chosenModel, type) {
  const TREE = Object.assign({}, oldTree);
  recursChange(TREE, depth, name, chosenModel, type);
  return TREE;
}

export const byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1");
  s = s.replace(/^\./, "");
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const createNameFromProperty = (prop) => {
  const parts = prop.split("_").map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());
  return parts.join(" ");
};

export const removeUnchanged = (newItem, oldItem) =>
  Object.keys(newItem).reduce((res, key) => {
    if (!isEqual(newItem[key], oldItem[key])) res[key] = newItem[key];
    return res;
  }, {});
