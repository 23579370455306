import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Badge, Breadcrumb, Button, Container, Form, Modal, Table } from "react-bootstrap";

import useToken from "../App/useToken";
import useUser from "../App/useUser";
import useSettings from "../App/useSettings";

import { toolsUrl } from "../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";

async function getTools(token, limit, offset) {
  return fetch(`${toolsUrl}?limit=${limit || 0}&offset=${offset || 0}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function cloneTool(token, toolId) {
  return fetch(`${toolsUrl}/${toolId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => console.log(e));
}

async function delTool(token, toolId) {
  return fetch(`${toolsUrl}/${toolId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => console.log(e));
}

export default function Tools(props) {
  const history = useHistory();
  const location = useLocation();
  const { token } = useToken();
  const { user } = useUser();
  const [tools, setTools] = useState([]);
  const [statuses, setStatuses] = useState({});
  const { settings, setSettings } = useSettings();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const [showDel, setShowDel] = useState(false);
  const [toolDelete, setToolDelete] = useState("");
  const [toolDelId, setToolDelId] = useState();

  const handleDelClose = () => {
    setToolDelId();
    setToolDelete("");
    setShowDel(false);
  };
  const handleDelShow = (toolId) => {
    setShowDel(true);
    setToolDelete("");
    setToolDelId(toolId);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("action") == "createTool") {
      history.push(`/tools/tool`);
    }

    loadTools(token, settings.items, offset);

    return () => setTools([]);
  }, [user, token]);

  const loadTools = (token, limit, offset) => {
    setError();
    getTools(token, limit, offset)
      .then((r) => {
        if (!r.list) {
          console.log(r);
          setError(r);
        }
        const _tools = offset ? tools.concat(r.list || []) : r.list || [];
        setTools(_tools);
        setTotal(r.total || tools.length);
      })
      .catch((e) => console.log(e));
  };

  const getMoreItems = () => {
    loadTools(token, settings.items, tools.length);
  };

  const copyTool = (toolId) => {
    cloneTool(token, toolId).then((r) => {
      console.log(r);
      if (r && r.toolId) loadTools(token, settings.items, offset);
    });
  };

  const removeTool = (toolId) => {
    delTool(token, toolId).then((r) => {
      console.log(r);
      setTools(tools.filter((item) => item.id != toolId));
    });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Tools</Breadcrumb.Item>
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <h2 className="my-4 text-center">Tools</h2>
      {tools ? (
        <InfiniteScroll
          dataLength={tools.length} //This is important field to render the next data
          next={getMoreItems}
          hasMore={total > tools.length}
          // loader={<Spinner accessibilityLabel="..." size="large" />}
          loader={""}
          endMessage={tools.length ? "..." : ""}
        >
          <Table striped bordered hover size="sm" className="align-middle">
            <thead>
              <tr key="0" className="align-middle">
                {user.is_admin ? <th>ID</th> : ""}
                <th>Title</th>
                <th>Description</th>
                <th>Parameters</th>
                <th>URL</th>
                <th>Options</th>
                <th>Created</th>
                <th>Modified</th>
                <th>
                  <Link to={`/tools/tool`}>
                    <Button variant="outline-success" size="sm" className="mx-1 my-1">
                      Add New ...
                    </Button>
                  </Link>
                </th>

              </tr>
            </thead>
            <tbody>
              {tools.length
                ? tools.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td><Link to={`/tools/tool/${item.id}`}>{item.title || item.name}</Link></td>
                    <td>{item.description}</td>
                    <td>{item.parameters}</td>
                    <td>{item.request_url}</td>
                    <td>{item.request_options}</td>
                    <td>{item.created}</td>
                    <td>{item.modified}</td>
                    <td>
                      <Button
                        id={item.id}
                        variant="outline-secondary"
                        size="sm"
                        className="mx-1 my-1"
                        onClick={(e) => copyTool(e.target.id)}
                      >
                        Clone
                      </Button>
                      <Link to={`/tools/tool/${item.id}`}>
                        <Button variant="outline-primary" size="sm" className="mx-1 my-1">
                          Edit
                        </Button>
                      </Link>
                      <Button
                        id={item.id}
                        variant="outline-danger"
                        size="sm"
                        onClick={(e) => handleDelShow(e.target.id)}
                        className="mx-1 my-1"
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))
                : ""}
            </tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        ""
      )}

      <Modal show={showDel} onHide={handleDelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove the Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to delete this Tool. This is irreversible. Please type the word "DELETE" in the field to confirm.
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="DELETE"
              value={toolDelete}
              onChange={(e) => setToolDelete(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              removeTool(toolDelId);
              handleDelClose();
            }}
            disabled={toolDelete != "DELETE"}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
