import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { pathsUrl, modelsUrl } from '../../utils/api';
import { heightAdjust } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { gpt3Encode, gpt3Decode } from '../GPT3/Encoder';

async function getPath(token, pathId) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function playPath(token, pathId, prompt) {

    return fetch(`${pathsUrl}/${pathId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            prompt: prompt,
        }),
    })
        .then(data => data.json());
}

async function playModel(token, modelId, prompt, modelVersion, pathId = null) {

    return fetch(`${modelsUrl}/${modelId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            path_id: pathId,
            prompt: prompt,
            model_version_id: modelVersion,
        }),
    })
        .then(data => data.json());
}

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createModelData(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function getModelHistoryList(token, modelId, pathId) {

    return fetch(`${modelsUrl}/${modelId}/history?pathId=${pathId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getHistory(token, modelId, historyId) {

    return fetch(`${modelsUrl}/${modelId}/history/${historyId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

// TODO

export default function PathPlay(props) {
    let { pathId: id } = useParams();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const { settings, setSettings } = useSettings();

    const [pathId, setPathId] = useState(id);
    const [pathTitle, setPathTitle] = useState('');
    const [pathModels, setPathModels] = useState([]);

    const [modelKey, setModelKey] = useState(0);
    const [modelId, setModelId] = useState();
    const [modelTitle, setModelTitle] = useState('');
    const [modelPrompt, setModelPrompt] = useState([]);
    const [modelCompletion, setModelCompletion] = useState([]);

    const [prompt, setPrompt] = useState('');
    const [modelHistoryList, setModelHistoryList] = useState([]);
    const [historyId, setHistoryId] = useState(null);
    const [history, setHistory] = useState({});

    const [modelDataPrompt, setModelDataPrompt] = useState([]);
    const [modelDataCompletion, setModelDataCompletion] = useState([]);

    const [step, setStep] = useState(0);
    const [stepPrompt, setStepPrompt] = useState();

    const [pathResults, setPathResults] = useState({});

    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            setPathId(id);
            loadPath();
        }

        return () => {
            setPathTitle('');
            setPathModels([]);

            setPathResults([]);
        }
    }, [token]);

    const loadPath = () => {
        // setError();
        getPath(token, pathId).then(r => {
            if (!r.path || !r.path.id) {
                console.log(r);
                setError(r);
            }
            const rModels = JSON.parse(r.path.models || []);
            setPathTitle(r.path.title);
            if (rModels.length) {
                setPathModels(rModels);
                setModelId(rModels[0]);
            }
        });
    };

    const runPath = () => {

        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;

        playPath(token, pathId, prompt).then(r => {
            console.log(r);

            if (!r.results) {
                console.log(r);
                setError(r);
            }
            setPrompt('');
            goButton.disabled = false;
            setPathResults(r.results);
        });
    };

    const pathPageTitle = `Path "${pathTitle}"`;

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/paths">Paths</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/paths/path/${pathId}`}>{pathPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item active>Sandbox</Breadcrumb.Item>
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {
                        `${error.error ?
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            ) : (error)
                        }`
                    }
                </Alert>
            )}

            <h2 className="my-2 text-center" key="pageTitle">
                {pathPageTitle}
            </h2>

            <Form>
                <Alert key={`pair-new`}>
                    <Form.Control
                        as="textarea"
                        placeholder="Enter prompt"
                        className="mb-2"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyUp={e => heightAdjust(e.target)}
                        style={{ overpath: 'hidden', height: '100px' }}
                    />
                    <Button id='goButton' type="button" onClick={(e) => {
                        runPath();
                    }}>Go Step by Step</Button>
                </Alert>
            </Form>

            {pathResults && pathResults.length ? (
                pathResults.map((pathResult, index) => 
                    <Alert key={`pair-${index}`}>
                        <h4>{pathResult.model_title}</h4>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{pathResult.completion}</pre>
                    </Alert>
                )) : ''}
        </>);
}