import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Container, Form, FormControl, InputGroup, Modal, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import Markdown from 'react-markdown'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import { playUrl } from '../../utils/api';

async function sendMessage(token, message, assistantId = null, threadId = null) {

    return fetch(`${playUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ assistantId, threadId, message }),
    })
        .then(data => data.json())
        .catch(e => {
            console.log(e);
        });
}

export default function Sandbox(props) {
    const history = useHistory();
    const location = useLocation();
    const { token } = useToken();
    const { user } = useUser();

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [sent, setSent] = useState();

    const query = new URLSearchParams(location.search);
    const assistantId = query.get('assistantId') || "asst_ChlneAi82TavhfuoSpZLyuPI";
    const threadId = query.get('threadId') || "thread_EN7Fb7Sg82969xzFxy35QR2a";

    const [error, setError] = useState(null);

    const submitMessage = () => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;

        sendMessage(token, question).then(r => {
            if (!r || !r.response || !r.response.answer) {
                console.log(r);
                setError(r);
            } else {
                setQuestion('');
                if (r.request && r.request.message) {
                    setSent(r.request.message);
                }
                setAnswer(r.response.answer);
            }
            goButton.disabled = false;
        });
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="#/sandbox">Sandbox</Breadcrumb.Item>
            </Breadcrumb>

            {
                error && (
                    <Alert variant="danger" className="row">
                        {`${error.error &&
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            )}`}
                    </Alert>
                )
            }

            <h2 className="my-2 text-center">
                Assistant
            </h2>

            <Row>
                <Col>
                    <Alert key={`question`} variant="primary">
                        <Form>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter question"
                                className="my-2"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                rows="8"
                            />
                            <Button id="goButton" type="button" onClick={(e) => { submitMessage(); }}>Go</Button>
                        </Form>
                    </Alert>
                </Col>
            </Row>

            <Row>
                <Col>
                    {sent && sent.length ? (
                        <Alert key={`sent`} variant="primary">
                            <Markdown>{sent}</Markdown>
                        </Alert>
                    ) : ''}
                </Col>
            </Row>

            <Row>
                <Col>
                    {answer && answer.length ? (
                        <Alert key={`answer`} variant="primary">
                            <Markdown>{answer}</Markdown>
                        </Alert>
                    ) : ''}
                </Col>
            </Row>
        </>
    );

}
