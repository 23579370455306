import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams, useNavigate, Redirect } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Card, Container, Form, FormControl, InputGroup, ListGroup, ProgressBar, Stack, Table } from 'react-bootstrap';
import { faCheck, faPencil, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { pathsUrl, modelsUrl, usersUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Autosuggest from 'react-autosuggest';
import './path.css';

async function getPath(token, pathId) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getCustomers(token) {

    return fetch(`${usersUrl}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModels(token) {

    return fetch(`${modelsUrl}?limit=1000&offset=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createPath(token, data) {

    return fetch(`${pathsUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updatePath(token, pathId, data) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delPath(token, pathId) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

// Model

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createModel(token, data) {

    return fetch(`${modelsUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function quickUpdateModel(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}/quick`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

// TODO

export default function Path(props) {
    const history = useHistory();
    const location = useLocation();
    let { pathId: id } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const { settings, setSettings } = useSettings();

    const [pathId, setPathId] = useState(id);
    const [pathTitle, setPathTitle] = useState('');
    const [pathUsers, setPathUsers] = useState([]);
    const [pathModels, setPathModels] = useState();

    // Managing Users in the Path
    const [customers, setCustomers] = useState([]);

    // Managing Models in the Path
    const [models, setModels] = useState([]);
    const [newModelId, setNewModelId] = useState();

    const [modelIdFocus, setModelIdFocus] = useState();
    const [modelFieldFocus, setModelFieldFocus] = useState();

    const [pathChanged, setPathChanged] = useState(false);

    const [error, setError] = useState(null);

    const color = (level, header) => {
        const colors = [
            [0, 1, 3],
            [0, 3, 1],
            [1, 3, 0],
            [3, 1, 0],
            [3, 0, 1],
            [1, 0, 3],
            [1, 1, 2],
            [1, 2, 1],
            [2, 1, 1],
            [0, 0, 4],
            [0, 4, 0],
            [4, 0, 0],
        ];

        const i = level % 4;

        return `rgba(${colors[i][0] * 24}, ${colors[i][1] * 24}, ${colors[i][2] * 24}, ${1 - (1 / (header + 1))})`;
    }

    const query = new URLSearchParams(location.search);
    const modelId = query.get('modelId');
    const modelAddr = query.get('m');

    useEffect(() => {
        loadCustomers();
        loadModels();

        if (id) {
            setPathId(id);
            loadPath();
        }

        return () => {
            setPathTitle('');
            setPathUsers([]);
            setPathModels([]);
        }
    }, [token]);

    useEffect(() => {
        if (pathModels && pathModels["values"]) {
            refreshPathCompletionValues();
        }
    }, [models]);

    const loadPath = (_pathId = null) => {
        // setError();
        getPath(token, _pathId || pathId).then(r => {
            if (!r.path || !r.path.id) {
                console.log(r);
                setError(r);
            }
            setPathTitle(r.path.title);
            setPathUsers(r.path.users ? r.path.users : []);
            const _models = r.path.models ? JSON.parse(r.path.models) : {
                "id": null
            };
            if (modelId && modelAddr) {
                const _created = byString(_models, modelAddr);
                _created['id'] = modelId;
            }
            setPathModels(_models);
        });
    };

    const loadCustomers = () => {
        // setError();
        getCustomers(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            }
            setCustomers(r.list.length ? r.list.map(customer => customer) : []);
        });
    };

    const refreshPathCompletionValues = (_addr = null) => {
        if (pathModels && pathModels["values"]) {
            // console.log('pathModels', pathModels);
            const _pathModels = Object.assign({}, pathModels);
            // console.log('_pathModels', _pathModels);
            // console.log('_addr', _addr);
            const _current = (_addr && _addr.length) ? byString(_pathModels, _addr.map(a => `[${a}]`).join('')) : _pathModels;
            // console.log('_current', _current);
            if (_current) {
                const currentModels = models.filter(model => model.id == _current.id);
                const currentModel = currentModels.length ? currentModels[0] : {};
                const currentClassification = currentModel.classification ? JSON.parse(currentModel.classification || '') : [];
                const _add = (_current["values"] && _current["values"].length) ? currentClassification.filter(c => !_current["values"].map(v => v.value).includes(c)).map(classificationValue => {
                    return {
                        "value": classificationValue,
                        "id": null,
                    };
                }) : [];
                // console.log([..._current["values"], ..._add]);
                if (_add.length) {
                    _current["values"] = [..._current["values"], ..._add];
                }
                if (_current["values"] && _current["values"].length) {
                    _current["values"].map((_pathModel, i) => refreshPathCompletionValues((_addr && _addr.length) ? [..._addr, "values", i] : ["values", i]));
                }
                setPathModels(_pathModels);
            }
        }
    };

    const loadModels = () => {
        // setError();
        getModels(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            } else {
                setModels(r.list.length ? r.list.map(model => model) : []);
            }
        });
    };

    const onSubmit = async (redirect, createNew = false) => {
        setError();
        const data = {
            title: pathTitle,
            users: pathUsers,
            models: JSON.stringify(pathModels),
            user_id: user.id,
        };
        if (pathId) {
            await updatePath(token, pathId, data);
            loadPath();
        } else {
            await createPath(token, data).then(r => {
                if (!r.pathId) {
                    console.log(r);
                    setError(r);
                }
                setPathId(r.pathId);
                history.push(`/paths/path/${r.pathId}`);
                loadPath(r.pathId);
            });
        }
        if (redirect) {
            if (createNew) {
                return history.push(`/paths?action=createPath`);
            } else {
                return history.push(`/paths`);
            }
        }
    };

    const removePath = (pathId) => {
        setError();
        if (window.confirm("Do you really want to delete this path?")) {
            delPath(token, pathId).then(r => {
                console.log(r);
                history.push(`/paths`);
            });
        }
    };

    const removePathModel = (index) => {
        setError();
        if (window.confirm("Do you really want to remove this model from the path?")) {
            setPathModels([...pathModels.filter((item, mIndex) => mIndex !== index)]);
        }
    };

    const byString = (o, s) => {
        s = s.replace(/\[(\w+)\]/g, '.$1');
        s = s.replace(/^\./, '');
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    };

    const pathPageTitle = pathId ? `Path "${pathTitle}"` : `Add New Path ${pathTitle ? `"${pathTitle}"` : ''}`;

    const getCustomerById = customerId => customers.filter(customer => customer.id == customerId)[0];

    const renderCustomer = customer => customer ? `${customer.first_name || ''} ${customer.last_name || ''} <${customer.email}>` : null;

    const renderCustomerTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
        return (
            <span key={key} {...other}>
                {renderCustomer(customers.filter(customer => customer.id == tag)[0])}
                {!disabled &&
                    <a className={classNameRemove} onClick={(e) => onRemove(key)} />
                }
            </span>
        )
    }

    const autocompleteRenderInput = ({ addTag, ...props }) => {
        const handleOnChange = (e, { newValue, method }) => {
            if (method === 'enter') {
                e.preventDefault();
            } else {
                props.onChange(e);
            }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
        const inputLength = inputValue.length;

        const suggestions = customers.filter(customer => !pathUsers.includes(customer.id) &&
            (
                `${customer.first_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.last_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.email}`.toLowerCase().slice(0, inputLength) === inputValue
            )
        );

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={value => renderCustomer(value)}
                getSuggestionValue={suggestion => suggestion.id}
                renderSuggestion={suggestion => <span>{renderCustomer(suggestion)}</span>}
                inputProps={{ ...props, onChange: handleOnChange }}
                onSuggestionSelected={(e, { suggestion }) => {
                    addTag(suggestion.id);
                }}
                onSuggestionsClearRequested={() => { }}
                onSuggestionsFetchRequested={() => { }}
            />
        )
    }

    const ModelCard = (props) => {
        const { pathModel, level, addr } = props;

        const currentModels = models.filter(model => model.id == pathModel.id);

        const currentModel = currentModels.length ? currentModels[0] : {};

        const currentPrompt = currentModel.prompt ? JSON.parse(currentModel.prompt || '') : [];

        const currentCompletion = currentModel.completion ? JSON.parse(currentModel.completion || '') : [];

        const currentClassification = currentModel.classification ? JSON.parse(currentModel.classification || '[]') : [];

        const currentTask = currentModel.class ?
            `${currentModel.task} ${currentModel.task_suffix || defaultTaskSuffix} ${currentClassification && currentClassification.length ? currentClassification.map(c => `"${c}"`).join(', ') + '.' : ''}` :
            `${currentModel.task}`;

        const _current = addr ? byString(_pathModels, addr.map(a => `[${a}]`).join('')) : _pathModels;

        const [modelTitle, setModelTitle] = useState(currentModel.title);
        const [modelTask, setModelTask] = useState(currentModel.task);
        const [modelPrompt, setModelPrompt] = useState(currentPrompt);
        const [modelCompletion, setModelCompletion] = useState(currentCompletion);
        const [modelClassification, setModelClassification] = useState(currentClassification);

        return <>
            <Card className="mt-2 mb-2 shadow model-card" style={{ width: "30rem" }} key={pathModel.id}>
                {pathModel.id && ['answer', 'classification'].includes(pathModel.id) ?
                    <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex px-2 py-2" title="Model Title">
                            <Badge bg="secondary" className="my-auto ms-1 me-1">{pathModel.value || 'Start'}</Badge>
                            <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Title"
                                onClick={async e => {
                                    const data = {
                                        title: modelTitle,
                                        format_phrase: null,
                                        class: pathModel.id == 'classification',
                                        task: modelTask,
                                        task_suffix: null,
                                        classification: JSON.stringify(modelClassification),
                                        users: [user.id],
                                        prompt: JSON.stringify(modelPrompt),
                                        completion: JSON.stringify(modelCompletion),
                                        prefix: null,
                                        user_id: user.id,
                                        base: null,
                                    };

                                    await createModel(token, data).then(r => {
                                        if (!r.modelId) {
                                            console.log(r);
                                            setError(r);
                                        }
                                        _current.id = r.modelId;
                                        loadModels();
                                        setPathModels(_pathModels);
                                    });
                                }}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <Form.Control type="text" size="sm" placeholder="* Title" className="my-auto ms-1 me-2 fw-bold"
                                value={modelTitle}
                                onChange={e => {
                                    setModelTitle(e.target.value);
                                }} />
                            <Button variant="outline-secondary" size="sm" className="ms-0 me-1 my-0" onClick={e => {
                                _current.id = null;
                                _current.values = null;
                                setPathModels(_pathModels);
                            }}>
                                <FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex px-2" title="Model Task">
                            <Form.Control type="text" size="sm" placeholder="* Task" className="me-2"
                                value={modelTask}
                                onChange={e => {
                                    setModelTask(e.target.value);
                                }}
                                onKeyDown={async e => {
                                    if (e.key === 'Enter') {
                                        await quickUpdateModel(token, currentModel.id, { task: modelTask });
                                    }
                                }}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex px-2" title="Model Prompt">
                            <Form.Control type="text" size="sm" placeholder="* Prompt" className="me-2"
                                value={modelPrompt[0]}
                                onChange={e => {
                                    setModelPrompt([e.target.value]);
                                }}
                                onKeyDown={async e => {
                                    if (e.key === 'Enter') {
                                        await quickUpdateModel(token, currentModel.id, { prompt: JSON.stringify(modelPrompt) });
                                    }
                                }}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex px-2" title="Model Completion">
                            <Form.Control type="text" size="sm" placeholder="* Completion" className="me-2"
                                value={modelCompletion[0]}
                                onChange={e => {
                                    setModelCompletion([e.target.value]);
                                }}
                                onKeyDown={async e => {
                                    if (e.key === 'Enter') {
                                        await quickUpdateModel(token, currentModel.id, { completion: JSON.stringify(modelCompletion) });
                                    }
                                }}
                            />
                        </ListGroup.Item>
                        {pathModel.id == 'classification' ?
                            <ListGroup.Item className="d-flex px-2" title="Model Completion Options">
                                <TagsInput
                                    disabled={!user.is_admin}
                                    // value={pathModel.values ? pathModel.values.map(v => v.value) : []}
                                    value={_current["values"] ? _current["values"].map(v => v.value) : currentClassification}
                                    onChange={values => {
                                        if (!pathChanged) {
                                            setPathChanged(true);
                                        }
                                        _current["values"] = values.map(v => {
                                            const existing = _current["values"] && _current["values"].length ?
                                                _current["values"].filter(m => m.value == v) :
                                                [];

                                            if (existing.length) {
                                                return existing[0];
                                            } else {
                                                return {
                                                    "value": v,
                                                    "id": null,
                                                };
                                            }
                                        });
                                        // setPathModels(_pathModels);
                                        setModelClassification(_current["values"] ? _current["values"].map(v => v.value) : currentClassification);
                                    }}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control px-2 me-2"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-1 px-1',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input mb-0',
                                        placeholder: user.is_admin ? '+' : '',
                                    }}
                                />
                            </ListGroup.Item>
                            : ''}
                    </ListGroup>
                    : ''}
                {pathModel.id && !['answer', 'classification'].includes(pathModel.id) ?
                    <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex px-2 py-2" title="Model Title">
                            <Badge bg="secondary" className="my-auto ms-1 me-1">{pathModel.value || 'Start'}</Badge>
                            <Form.Control type="text" size="sm" placeholder="title" className="my-auto ms-1 me-2 fw-bold"
                                value={modelTitle}
                                onChange={e => {
                                    setModelTitle(e.target.value);
                                }} />
                            {/* <span className="my-auto ms-auto me-auto"><strong>{currentModel.title}</strong></span> */}
                            {/* <Button disabled variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" onClick={e => history.push(`/models/model/${pathModel.id}?pathId=${pathId}`)}>
                                <FontAwesomeIcon icon={faPencil} />
                            </Button> */}
                            <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Title"
                                onClick={async e => {
                                    await quickUpdateModel(token, currentModel.id, { title: modelTitle });
                                }}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <Button variant="outline-secondary" size="sm" className="ms-0 me-1 my-0" onClick={e => {
                                // const _current = addr ? byString(_pathModels, addr.map(a => `[${a}]`).join('')) : _pathModels;
                                _current.id = null;
                                _current.values = null;
                                setPathModels(_pathModels);
                            }}>
                                <FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                        </ListGroup.Item>
                        {currentModel.task ?
                            <ListGroup.Item className="d-flex px-2" title="Model Task">
                                {/* <InputGroup size="sm"> */}
                                {/* <InputGroup.Text>Prompt</InputGroup.Text> */}
                                <Form.Control type="text" size="sm" placeholder="task" className="me-2"
                                    value={modelTask}
                                    onChange={e => {
                                        setModelTask(e.target.value);
                                    }}
                                    onKeyDown={async e => {
                                        if (e.key === 'Enter') {
                                            await quickUpdateModel(token, currentModel.id, { task: modelTask });
                                        }
                                    }}
                                />
                                <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Task"
                                    onClick={async e => {
                                        await quickUpdateModel(token, currentModel.id, { task: modelTask });
                                    }}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                {/* <span
                                        className="text-truncate"
                                        title={currentTask}>
                                        <small>{currentTask}</small>
                                    </span> */}
                                {/* </InputGroup> */}
                            </ListGroup.Item>
                            : ''
                        }
                        {currentPrompt.length ?
                            <ListGroup.Item className="d-flex px-2" title="Model Prompt">
                                {/* <InputGroup size="sm"> */}
                                {/* <InputGroup.Text>Prompt</InputGroup.Text> */}
                                <Form.Control type="text" size="sm" placeholder="prompt" className="me-2"
                                    value={modelPrompt[0]}
                                    onChange={e => {
                                        setModelPrompt([e.target.value]);
                                    }}
                                    onKeyDown={async e => {
                                        if (e.key === 'Enter') {
                                            await quickUpdateModel(token, currentModel.id, { prompt: JSON.stringify(modelPrompt) });
                                        }
                                    }}
                                />
                                {/* <span><small><strong>{currentPrompt[0]}</strong></small></span> */}
                                <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Prompt"
                                    onClick={async e => {
                                        await quickUpdateModel(token, currentModel.id, { prompt: JSON.stringify(modelPrompt) });
                                    }}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                {/* </InputGroup> */}
                            </ListGroup.Item>
                            : ''
                        }
                        {currentCompletion.length ?
                            <ListGroup.Item className="d-flex px-2" title="Model Completion">
                                <Form.Control type="text" size="sm" placeholder="completion" className="me-2"
                                    value={modelCompletion[0]}
                                    onChange={e => {
                                        setModelCompletion([e.target.value]);
                                    }}
                                    onKeyDown={async e => {
                                        if (e.key === 'Enter') {
                                            await quickUpdateModel(token, currentModel.id, { completion: JSON.stringify(modelCompletion) });
                                        }
                                    }}
                                />
                                {/* <span><small><strong>{currentCompletion[0]}</strong></small></span> */}
                                <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Completion"
                                    onClick={async e => {
                                        await quickUpdateModel(token, currentModel.id, { completion: JSON.stringify(modelCompletion) });
                                    }}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                {/* </InputGroup> */}
                            </ListGroup.Item> : ''
                        }
                        {currentModel.class ?
                            <ListGroup.Item className="d-flex px-2" title="Model Completion Options">
                                {/* <InputGroup> */}
                                <TagsInput
                                    disabled={!user.is_admin}
                                    // value={pathModel.values ? pathModel.values.map(v => v.value) : []}
                                    value={_current["values"] ? _current["values"].map(v => v.value) : currentClassification}
                                    onChange={values => {
                                        if (!pathChanged) {
                                            setPathChanged(true);
                                        }
                                        _current["values"] = values.map(v => {
                                            const existing = _current["values"] && _current["values"].length ?
                                                _current["values"].filter(m => m.value == v) :
                                                [];

                                            if (existing.length) {
                                                return existing[0];
                                            } else {
                                                return {
                                                    "value": v,
                                                    "id": null,
                                                };
                                            }
                                        });
                                        setPathModels(_pathModels);
                                        setModelClassification(_current["values"] ? _current["values"].map(v => v.value) : currentClassification);
                                    }}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control px-2 me-2"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-1 px-1',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input mb-0',
                                        placeholder: user.is_admin ? '+' : '',
                                    }}
                                />
                                <Button variant="outline-secondary" size="sm" className="ms-auto me-1 my-0" title="Save Model Completion"
                                    onClick={async e => {
                                        await quickUpdateModel(token, currentModel.id, { classification: JSON.stringify(_current["values"] ? _current["values"].map(v => v.value) : currentClassification) });
                                        onSubmit(false, false);
                                    }}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                {/* </InputGroup> */}
                            </ListGroup.Item> : ''
                        }
                    </ListGroup>
                    : ''}
                {!pathModel.id ?
                    <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex px-2 py-2" title="Create or Add Model">
                            <Badge bg="secondary" className="my-auto ms-1 me-1">{pathModel.value || 'Start'}</Badge>
                            {/* <Form.Control type="text" size="sm" placeholder="prompt" value={currentModel.title} disabled /> */}
                            <Form.Select
                                size="sm"
                                className="my-3"
                                value={newModelId}
                                onChange={e => {
                                    const _current = addr ? byString(_pathModels, addr.map(a => `[${a}]`).join('')) : _pathModels;
                                    _current.id = e.target.value;
                                    // if (_current.id == 'answer') {
                                    //     setCreateModel('answer');
                                    // }
                                    // else if (_current.id == 'classification') {
                                    //     setCreateModel('classification');
                                    // }
                                    const currentModels = models.filter(model => model.id == _current.id);
                                    const currentModel = currentModels.length ? currentModels[0] : {};
                                    const currentClassification = currentModel.classification ? JSON.parse(currentModel.classification || '') : [];
                                    _current.values = currentClassification.map(c => {
                                        return {
                                            "value": c,
                                            "id": null,
                                        };
                                    });
                                    setPathModels(_pathModels);
                                }}
                            >
                                <option disabled selected value="0">Create or Add Model</option>
                                <option
                                    value="answer"
                                    className="text-success link-success"
                                >Create New Answer Model</option>
                                <option
                                    value="classification"
                                    className="text-success link-success"
                                >Create New Classification Model</option>
                                {models ?
                                    models.map(model => (
                                        <option value={model.id}>{model.title}</option>
                                    )) :
                                    ''}
                            </Form.Select>
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                            <small>
                                {addr && addr.map(a => `["${a}"]`).join('')}
                                <br />
                                {addr && JSON.stringify(byString(pathModels, addr.map(a => `[${a}]`).join('')))}
                            </small>
                        </ListGroup.Item> */}
                    </ListGroup>
                    : ''}
            </Card>
            {pathModel.values && pathModel.values.length ?
                <ul style={{ listStyleType: "none" }}>
                    <ul style={{ listStyleType: "none" }}>
                        <ul style={{ listStyleType: "none" }}>
                            {models.length && pathModel.values && pathModel.values.map((v, i) =>
                                <li>
                                    <ModelCard pathModel={v} level={level + 1} addr={addr && addr.length ? [...addr, "values", i] : ["values", i]} />
                                </li>
                            )}
                        </ul>
                    </ul>
                </ul> : ''}
        </>
    };

    const _pathModels = Object.assign({}, pathModels);

    const defaultTaskSuffix = 'Classify if the prompt is one of the following:';

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item href="#/paths">Paths</Breadcrumb.Item>
                <Breadcrumb.Item active>{pathPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/paths/path/${pathId}/sandbox`}>Sandbox</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}

            <h2 className="my-2 text-center">
                {pathPageTitle}
            </h2>
            <Form>
                <h4 className="mt-2">Title</h4>
                <Form.Group className="mb-2">
                    <Form.Control type="text" placeholder="Enter title" value={pathTitle} onChange={e => setPathTitle(e.target.value)} />
                </Form.Group>
                {user.is_admin ? (
                    <>
                        <h4 className="mt-4">Users</h4>
                        <Form.Group className="mb-2">
                            {/* <Form.Label>Users</Form.Label> */}
                            <InputGroup>
                                <TagsInput
                                    renderInput={autocompleteRenderInput}
                                    renderTag={renderCustomerTag}
                                    value={pathUsers}
                                    onChange={values => setPathUsers(values.filter(value => customers.map(customer => customer.id).includes(value)))}
                                    disabled={!user.is_admin}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: user.is_admin ? 'Add ...' : '',
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                    </>
                ) : ''}
                <div className="d-flex">
                    <Link to={`/paths`}>
                        <Button
                            variant="secondary"
                            className="me-1 my-1"
                            size="sm"
                        >
                            &lt; Close
                        </Button>
                    </Link>
                    <Button
                        variant="primary"
                        className="me-1 my-1 ms-auto"
                        onClick={e => onSubmit(true, false)}
                        size="sm"
                    >
                        &lt; Save and Close
                    </Button>
                    <Button
                        variant="primary"
                        className="mx-1 my-1"
                        onClick={e => onSubmit(false, false)}
                        size="sm"
                    >
                        Save Changes
                    </Button>
                </div>
            </Form>
            <h4 className="mt-4">Path Start</h4>
            {models && models.length && pathModels ? <ModelCard pathModel={pathModels} level={0} /> : ''}
        </>);
}