import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams, useNavigate, Redirect } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  ProgressBar,
  Stack,
  Table,
  Row,
} from "react-bootstrap";
import { faCheck, faPencil, faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tree from "react-d3-tree";

import useToken from "../App/useToken";
import useUser from "../App/useUser";
import { pathsUrl, modelsUrl, usersUrl } from "../../utils/api";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import "./path_drop.css";

import { CoverRecurs, byString } from "../../utils/utils";

async function getPath(token, pathId) {
  return fetch(`${pathsUrl}/${pathId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
async function getCustomers(token) {
  return fetch(`${usersUrl}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
async function getModels(token) {
  return fetch(`${modelsUrl}?limit=1000&offset=0`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
async function createPath(token, data) {
  return fetch(`${pathsUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
async function updatePath(token, pathId, data) {
  return fetch(`${pathsUrl}/${pathId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
async function delPath(token, pathId) {
  return fetch(`${pathsUrl}/${pathId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
async function getModel(token, modelId) {
  return fetch(`${modelsUrl}/${modelId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
async function createModel(token, data) {
  return fetch(`${modelsUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
async function quickUpdateModel(token, modelId, data) {
  return fetch(`${modelsUrl}/${modelId}/quick`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
async function delModel(token, modelId) {
  return fetch(`${modelsUrl}/${modelId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());
}

export default function PathDrop(props) {
  const history = useHistory();
  const location = useLocation();
  let { pathId: id } = useParams();
  const { token } = useToken();
  const { user } = useUser();
  const [pathId, setPathId] = useState(id);
  const [pathTitle, setPathTitle] = useState("");
  const [pathUsers, setPathUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [models, setModels] = useState([]);
  const [error, setError] = useState(null);
  const [pathState, setPathState] = useState({
    isEditView: false,
    isActiveElement: false,
    treeData: {
      id: null,
      name: "Start",
      attributes: {
        title: "",
      },
      children: [],
    },
  });

  const query = new URLSearchParams(location.search);
  const modelId = query.get("modelId");
  const modelAddr = query.get("m");

  useEffect(() => {
    loadCustomers();
    loadModels();

    if (id) {
      setPathId(id);
      loadPath();
    }

    return () => {
      setPathTitle("");
      setPathUsers([]);
    };
  }, [token]);

  const loadPath = (_pathId = null) => {
    // setError();
    getPath(token, _pathId || pathId).then((r) => {
      // console.log("getPath", r);

      if (!r.path || !r.path.id) {
        console.log(r);
        setError(r);
      }
      setPathTitle(r.path.title);
      setPathUsers(r.path.users ? r.path.users : []);

      const _models = r.path.models && JSON.parse(r.path.models);

      console.log("_models", _models);

      if (!_models.hasOwnProperty("name")) {
        _models["name"] = "Start";
      }
      if (!_models.hasOwnProperty("attributes")) {
        _models["attributes"] = { title: "" };
      }
      if (!_models.hasOwnProperty("children")) {
        _models["children"] = [];
      }

      setPathState((pS) => ({ ...pS, treeData: _models }));
    });
  };
  const loadCustomers = () => {
    // setError();
    getCustomers(token).then((r) => {
      if (!r.list || !r.list.length) {
        console.log(r);
        setError(r);
      }
      setCustomers(r.list.length ? r.list.map((customer) => customer) : []);
    });
  };
  const loadModels = () => {
    // setError();
    getModels(token).then((r) => {
      if (!r.list || !r.list.length) {
        console.log(r);
        setError(r);
      } else {
        setModels(r.list.length ? r.list.map((model) => model) : []);
      }
    });
  };
  const onSubmit = async (redirect, createNew = false) => {
    setError();
    const data = {
      title: pathTitle,
      users: pathUsers,
      models: JSON.stringify(pathState.treeData),
      user_id: user.id,
    };
    if (pathId) {
      await updatePath(token, pathId, data).then((r) => {
        // console.log(r);
      });
      loadPath();
    } else {
      await createPath(token, data).then((r) => {
        if (!r.pathId) {
          console.log(r);
          setError(r);
        }
        setPathId(r.pathId);
        history.push(`/paths/path/${r.pathId}`);
        loadPath(r.pathId);
      });
    }
    if (redirect) {
      if (createNew) {
        return history.push(`/paths?action=createPath`);
      } else {
        return history.push(`/paths`);
      }
    }
  };

  const pathPageTitle = pathId ? `Path "${pathTitle}"` : `Add New Path ${pathTitle ? `"${pathTitle}"` : ""}`;
  const getCustomerById = (customerId) => customers.filter((customer) => customer.id == customerId)[0];
  const renderCustomer = (customer) =>
    customer ? `${customer.first_name || ""} ${customer.last_name || ""} <${customer.email}>` : null;
  const renderCustomerTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <span key={key} {...other}>
        {renderCustomer(customers.filter((customer) => customer.id == tag)[0])}
        {!disabled && <a className={classNameRemove} onClick={(e) => onRemove(key)} />}
      </span>
    );
  };
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const inputValue = (props.value && props.value.trim().toLowerCase()) || "";
    const inputLength = inputValue.length;

    const suggestions = customers.filter(
      (customer) =>
        !pathUsers.includes(customer.id) &&
        (`${customer.first_name}`.toLowerCase().slice(0, inputLength) === inputValue ||
          `${customer.last_name}`.toLowerCase().slice(0, inputLength) === inputValue ||
          `${customer.email}`.toLowerCase().slice(0, inputLength) === inputValue)
    );

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={suggestions}
        shouldRenderSuggestions={(value) => renderCustomer(value)}
        getSuggestionValue={(suggestion) => suggestion.id}
        renderSuggestion={(suggestion) => <span>{renderCustomer(suggestion)}</span>}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.id);
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    );
  };

  const onDragStart = (e) => {
    setPathState((pS) => ({ ...pS, isActiveElement: e.target.id }));
  };
  const onDragEnd = (e) => {
    setPathState((pS) => ({ ...pS, isActiveElement: null }));
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };
  const onDrop = (e, r) => {
    modifTree(pathState.isActiveElement, e);
  };

  const modifTree = (modelId, placeForPut) => {
    if (!modelId) return;
    const chosenModel = models.find(({ id }) => id === +modelId);
    if (!chosenModel) return;

    setPathState((pS) => {
      const FF = CoverRecurs({ ...pS.treeData }, placeForPut.depth, placeForPut.data.name, chosenModel, "addNewModal");
      return { ...pS, treeData: FF };
    });
  };

  const closeEdit = () => {
    setPathState((pS) => ({ ...pS, isEditView: null }));
  };

  const deleteModelFromPath = (node) => {
    if (window.confirm("Do you really want to remove this model from the path?")) {
      setPathState((pS) => {
        const FF = CoverRecurs({ ...pS.treeData }, node.depth, node.data.name, null, "delete");
        return { ...pS, treeData: FF, isEditView: null };
      });
    }
  };

  const modifyPathByModalUpdate = (node, data) => {
    setPathState((pS) => {
      const FF = CoverRecurs({ ...pS.treeData }, node.depth, node.data.name, data, "modify");
      return { ...pS, treeData: FF };
    });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href="#/paths">Paths</Breadcrumb.Item>
        <Breadcrumb.Item active>{pathPageTitle}</Breadcrumb.Item>
        <Breadcrumb.Item href={`#/paths/path/${pathId}/sandbox`}>Sandbox</Breadcrumb.Item>
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <h2 className="my-2 text-center">{pathPageTitle}</h2>
      <Form>
        <h4 className="mt-2">Title</h4>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={pathTitle}
            onChange={(e) => setPathTitle(e.target.value)}
          />
        </Form.Group>
        {user.is_admin && (
          <>
            <h4 className="mt-4">Users</h4>
            <Form.Group className="mb-2">
              {/* <Form.Label>Users</Form.Label> */}
              <InputGroup>
                <TagsInput
                  renderInput={autocompleteRenderInput}
                  renderTag={renderCustomerTag}
                  value={pathUsers}
                  onChange={(values) =>
                    setPathUsers(values.filter((value) => customers.map((customer) => customer.id).includes(value)))
                  }
                  disabled={!user.is_admin}
                  onlyUnique
                  addOnBlur
                  className="form-control"
                  tagProps={{
                    className: "badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2",
                    classNameRemove: "btn-close btn-close-white ml-2",
                  }}
                  inputProps={{
                    className: "react-tagsinput-input",
                    placeholder: user.is_admin ? "Add ..." : "",
                  }}
                />
              </InputGroup>
            </Form.Group>
          </>
        )}
        <div className="d-flex">
          <Link to={`/paths`}>
            <Button variant="secondary" className="me-1 my-1" size="sm">
              &lt; Close
            </Button>
          </Link>
          <Button variant="primary" className="me-1 my-1 ms-auto" onClick={(e) => onSubmit(true, false)} size="sm">
            &lt; Save and Close
          </Button>
          <Button variant="primary" className="mx-1 my-1" onClick={(e) => onSubmit(false, false)} size="sm">
            Save Changes
          </Button>
        </div>
      </Form>
      <h4 className="mt-4">Path Start</h4>

      <Row className="flow_container">
        {pathState.isEditView ? (
          <Col xs={4} className="flow_leftBlock edit">
            <Edit props={pathState.isEditView} />
          </Col>
        ) : (
          <Col xs={4} className="flow_leftBlock mainScreen">
            <div>
              <div className="models_list">
                {models &&
                  models.map((act) => {
                    return (
                      <div
                        key={act.id}
                        draggable={true}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        id={act.id}
                        className="mb-3 bg-secondary bg-gradient mx-1 my-1 py-2 px-2 action_block"
                      >
                        {act.title}
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
        )}
        <Col
          className="flow_rightBlock"
          id="treeWrapper"
          // onDrop={onDropStart}
          onDragOver={onDragOver}
        >
          <Tree
            pathClassFunc={() => "node__branch"}
            translate={{ x: 200, y: 200 }}
            scaleExtent={{ max: 3, min: 0.01 }}
            renderCustomNodeElement={TTTT}
            data={pathState.treeData}
            draggable={true}
            orientation="vertical"
            onNodeClick={(node, evt) => {
              closeEdit();
              setTimeout(() => {
                if (node.data.id) {
                  const pathModel = models.find(({ id }) => id === node.data.id);
                  setPathState((pS) => ({
                    ...pS,
                    isEditView: {
                      node,
                      pathModel,
                      user,
                      token,
                      loadModels,
                      closeEdit,
                      deleteModelFromPath,
                      modifyPathByModalUpdate,
                    },
                  }));
                }
              }, 300);
            }}
            onNodeMouseOut={onDrop}
            nodeSize={{ x: 420, y: 400 }}
          />
        </Col>
      </Row>
    </>
  );
}

function TTTT(props) {
  const { attributes, name } = props?.nodeDatum;

  let attributes_firstLine = attributes?.title;
  let attributes_secondLine = null;  

  if (attributes?.title && attributes?.title.length > 40) {
    attributes_firstLine = attributes?.title.slice(0, 40);
    attributes_secondLine = attributes?.title.slice(40);
  }

  return (
    <g onClick={props.onNodeClick} className="svg_container" onDrop={props.onNodeMouseOut}>
      <rect x="-125" y="-70" className="svg_rect" width="350" height="100" rx="15" />
      <text x="-110" y="-45" className="svg_name">
        {name}
      </text>
      <text x="-110" y="-20" className="svg_title">
        {attributes_firstLine}
      </text>
      {attributes_secondLine && (
        <text x="-110" y="0" className="svg_title">
          {attributes_secondLine}
        </text>
      )}
    </g>
  );
}

function Edit({
  props: { node, pathModel, user, token, loadModels, closeEdit, deleteModelFromPath, modifyPathByModalUpdate },
}) {
  // console.log("pathModel", pathModel);

  const currentPrompt = pathModel?.prompt ? JSON.parse(pathModel.prompt || "") : [];
  const currentCompletion = pathModel?.completion ? JSON.parse(pathModel.completion || "") : [];
  const currentClassification = pathModel?.classification ? JSON.parse(pathModel.classification || "[]") : [];

  const [modelTitle, setModelTitle] = useState(pathModel?.title);
  const [modelTask, setModelTask] = useState(pathModel?.task);
  const [modelPrompt, setModelPrompt] = useState(currentPrompt);
  const [modelCompletion, setModelCompletion] = useState(currentCompletion);
  const [modelClassification, setModelClassification] = useState(currentClassification);

  if (!pathModel) {
    closeEdit();
    return;
  }

  return (
    <Card className="mt-2 mb-2 shadow model-card" style={{ width: "100%" }} key={pathModel.id}>
      <ListGroup variant="flush">
        <ListGroup.Item className="d-flex px-2 py-2" title="Model Title">
          <Badge bg="secondary" className="my-auto ms-1 me-1">
            {node?.data?.name || "Start"}
          </Badge>
          <Form.Control
            type="text"
            size="sm"
            placeholder="* Title"
            className="my-auto ms-1 me-2 fw-bold"
            value={modelTitle}
            onChange={(e) => setModelTitle(e.target.value)}
          />
          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-auto me-1 my-0"
            title="Save Model Title"
            onClick={async (e) => {
              await quickUpdateModel(token, pathModel.id, {
                classification: JSON.stringify(modelClassification),
              }).then((r) => {
                // console.log("quickUpdateModel title", r);
                const data = {
                  title: modelTitle,
                };

                modifyPathByModalUpdate(node, data);
                loadModels();
              });
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button
            variant="outline-secondary"
            title="Delete Model From Path"
            size="sm"
            className="ms-0 me-1 my-0"
            onClick={(e) => deleteModelFromPath(node)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex px-2" title="Model Task">
          <Form.Control
            type="text"
            size="sm"
            placeholder="* Task"
            className="me-2"
            value={modelTask}
            onChange={(e) => setModelTask(e.target.value)}
          />
          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-auto me-1 my-0"
            title="Save Model Task"
            onClick={async (e) => {
              await quickUpdateModel(token, pathModel.id, { task: modelTask });
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex px-2" title="Model Prompt">
          <Form.Control
            type="text"
            size="sm"
            placeholder="* Prompt"
            className="me-2"
            value={modelPrompt[0]}
            onChange={(e) => setModelPrompt([e.target.value])}
          />
          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-auto me-1 my-0"
            title="Save Model Prompt"
            onClick={async (e) => {
              await quickUpdateModel(token, pathModel.id, { prompt: JSON.stringify(modelPrompt) });
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex px-2" title="Model Completion">
          <Form.Control
            type="text"
            size="sm"
            placeholder="* Completion"
            className="me-2"
            value={modelCompletion[0]}
            onChange={(e) => setModelCompletion([e.target.value])}
          />
          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-auto me-1 my-0"
            title="Save Model Completion"
            onClick={async (e) => {
              await quickUpdateModel(token, pathModel.id, { completion: JSON.stringify(modelCompletion) });
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex px-2" title="Model Classifications">
          <TagsInput
            disabled={!user.is_admin}
            // value={pathModel.values ? pathModel.values.map(v => v.value) : []}
            value={modelClassification}
            onChange={(values) => setModelClassification(values)}
            onlyUnique
            addOnBlur
            className="form-control px-2 me-2"
            tagProps={{
              className: "badge bg-secondary bg-gradient mx-1 my-1 py-1 px-1",
              classNameRemove: "btn-close btn-close-white ml-2",
            }}
            inputProps={{
              className: "react-tagsinput-input mb-0",
              placeholder: user.is_admin ? "+" : "",
            }}
          />
          <Button
            variant="outline-secondary"
            size="sm"
            className="ms-auto me-1 my-0"
            title="Save Model Classifications"
            onClick={async (e) => {
              await quickUpdateModel(token, pathModel.id, {
                classification: JSON.stringify(modelClassification),
              }).then((r) => {
                // console.log("quickUpdateModel Classifications", r);
                const data = {
                  classification: JSON.stringify(modelClassification),
                };

                modifyPathByModalUpdate(node, data);
              });
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ListGroup.Item>
      </ListGroup>

      <Button onClick={closeEdit}>Close</Button>
    </Card>
  );
}
