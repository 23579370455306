const isLocal = window.location.origin.includes('.local') || window.location.origin.includes('localhost');

// export const brandUrl = 'http://strietzel.local:3000/';
// export const brandUrl = 'http://app.strietzel.ai/';

const backendUrl = 'https://api.strietzel.ai/v1/';
// const apiUrl = 'https://strietzelai-api-nodejs-qzdef.ondigitalocean.app/';

// export const loginUrl = backendUrl + 'users/login';
export const loginUrl = backendUrl + 'users/shopify_login';
// export const registerUrl = backendUrl + 'users/register';
export const registerUrl = backendUrl + 'users/shopify_register';
export const modelsUrl = backendUrl + 'models';
export const toolsUrl = backendUrl + 'tools';
export const pathsUrl = backendUrl + 'paths';
export const flowsUrl = backendUrl + 'flows';
export const usersUrl = backendUrl + 'users';
export const dashboardUrl = backendUrl + 'dashboard';
export const apiKeysUrl = backendUrl + 'users/me/api_key';
export const balanceUrl = backendUrl + 'users/me/balance';
export const statUrl = backendUrl + 'users/me/statistics';
export const docUrl = 'https://api.strietzel.ai/data/doc/doc.html';
export const companiesUrl = backendUrl + 'companies';
export const playUrl = backendUrl + 'assistants/play';


// Useful functions library
export function removeParams(sParam) {
    let url = window.location.href.split('?')[0] + '?';
    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParamName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParamName = sURLVariables[i].split('=');
        if (sParamName[0] != sParam) {
            url = url + sParamName[0] + '=' + sParamName[1] + '&'
        }
    }

    return url.substring(0, url.length - 1);
}