import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Form, Modal, Table } from 'react-bootstrap';

import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';

import { flowsUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';

async function getFlows(token, limit, offset) {

    return fetch(`${flowsUrl}?limit=${limit || 0}&offset=${offset || 0}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function delFlow(token, flowId) {

    return fetch(`${flowsUrl}/${flowId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function Flows(props) {
    const history = useHistory();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [flows, setFlows] = useState([]);
    const { settings, setSettings } = useSettings();
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(null);

    const [showDel, setShowDel] = useState(false);
    const [flowDelete, setFlowDelete] = useState('');
    const [flowDelId, setFlowDelId] = useState();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get('action') == 'createFlow') {
            history.push(`/flows/flow`);
        }

        loadFlows(token, settings.items, offset);

        return () => setFlows([]);
    }, [user, token]);

    const handleDelClose = () => {
        setFlowDelId();
        setFlowDelete('');
        setShowDel(false);
    }
    const handleDelShow = (flowId) => {
        setShowDel(true);
        setFlowDelete('');
        setFlowDelId(flowId);
        console.log(flowId);
    }

    const loadFlows = (token, limit, offset) => {
        setError();
        getFlows(token, limit, offset).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setTotal(r.total || flows.length);
            setFlows(offset ? flows.concat(r.list || []) : (r.list || []));
        });
    }

    const getMoreItems = () => {
        loadFlows(token, settings.items, flows.length);
    }

    const removeFlow = (flowId) => {
        delFlow(token, flowId).then(r => {
            console.log(r);
            setFlows(flows.filter(item => item.id != flowId));
        });
    };

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Flows</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}

            <h2 className="my-4 text-center">Flows</h2>
            {flows ? (
                <InfiniteScroll
                    dataLength={flows.length} //This is important field to render the next data
                    next={getMoreItems}
                    hasMore={total > flows.length}
                    // loader={<Spinner accessibilityLabel="..." size="large" />}
                    loader={''}
                    endMessage={flows.length ?
                        ('...') :
                        ''
                    }
                >
                    <Table striped bordered hover size="sm" className="align-middle">
                        <thead>
                            <tr key="0" className="align-middle">
                                {user.is_admin ? (<th>ID</th>) : ''}
                                <th>Title</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Models</th>
                                {user.is_admin ? (<th>Users</th>) : ''}
                                {user.is_admin ? (
                                    <th>
                                        <Link to={`/flows/flow`}>
                                            <Button variant="outline-success" size="sm" className="mx-1 my-1">
                                                Add New ...
                                            </Button>
                                        </Link>
                                    </th>
                                ) : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {flows.length ? flows.map(item => (
                                <tr key={item.id}>
                                    {user.is_admin ? (<td>{item.id}</td>) : ''}
                                    <td>
                                        <Link to={`/flows/flow/${item.id}`}>
                                            {item.title}
                                        </Link>
                                    </td>
                                    <td>{item.created}</td>
                                    <td>{item.modified}</td>
                                    <td>{item.models ? (JSON.parse(item.models) || []).length : 0}</td>
                                    {user.is_admin ? (<td>{item.c_users}</td>) : ''}
                                    {user.is_admin ? (
                                        <td>
                                            <Link to={`/flows/flow/${item.id}`}>
                                                <Button variant="outline-primary" size="sm" className="mx-1 my-1">
                                                    Edit
                                                </Button>
                                            </Link>
                                            <Button id={item.id} variant="outline-danger" size="sm" onClick={e => handleDelShow(e.target.id)} className="mx-1 my-1">
                                                Remove
                                            </Button>
                                            <Modal show={showDel} onHide={handleDelClose} centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Remove the Flow</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    You want to delete this Flow. This is irreversible. Please type the word "DELETE" in the field to confirm.
                                                    <Form.Group className="mb-2">
                                                        <Form.Control type="text" placeholder="DELETE" value={flowDelete} onChange={e => setFlowDelete(e.target.value)} />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={e => {
                                                        removeFlow(flowDelId);
                                                        handleDelClose();
                                                    }} disabled={flowDelete != 'DELETE'}>
                                                        OK
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    ) : ''}
                                </tr>
                            )) : ''}
                        </tbody>
                    </Table>
                </InfiniteScroll>
            ) : ''}
        </>);
}