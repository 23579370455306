import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Container, Form, FormControl, InputGroup, Modal, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import { faCheck, faPencil, faTrashCan, faPlus, faClone, faAnglesDown, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { toolsUrl } from '../../utils/api';
// import { heightAdjust, prettyJson } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
// import TagsInput from 'react-tagsinput';
// import 'react-tagsinput/react-tagsinput.css';
// import Autosuggest from 'react-autosuggest';

async function getTool(token, toolId) {

    return fetch(`${toolsUrl}/${toolId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createTool(token, data) {

    return fetch(`${toolsUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateTool(token, toolId, data) {

    return fetch(`${toolsUrl}/${toolId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delTool(token, toolId) {

    return fetch(`${toolsUrl}/${toolId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getToolDataList(token, toolId, limit, offset, version = null) {

    return fetch(`${toolsUrl}/${toolId}/data?limit=${limit || 0}&offset=${offset || 0}${version ? `?version=${version}` : ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function delToolData(token, toolId, toolDataId) {

    return fetch(`${toolsUrl}/${toolId}/data/${toolDataId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function uploadFileCsv(token, toolId, fileFormData, replaceData = false) {

    return fetch(`${toolsUrl}/${toolId}/data/import${replaceData ? `?replace=1` : ''}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: fileFormData,
    })
        .then(data => data.json());
}

async function prepareExportFileCsv(token, toolId) {

    return fetch(`${toolsUrl}/${toolId}/data/export`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function Tool(props) {
    const history = useHistory();
    const location = useLocation();
    const { settings, setSettings } = useSettings();
    let { toolId: id } = useParams();
    const { token } = useToken();
    const { user } = useUser();
    const [toolId, setToolId] = useState(id);
    const [toolTitle, setToolTitle] = useState('');
    const [toolName, setToolName] = useState('');
    const [toolDescription, setToolDescription] = useState('');
    const [toolParameters, setToolParameters] = useState('');
    const [toolRequestUrl, setToolRequestUrl] = useState('');
    const [toolRequestOptions, setToolRequestOptions] = useState('');
    const [selectedFileCsv, setSelectedFileCsv] = useState(null);
    const [toolDataList, setToolDataList] = useState([]);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [replaceData, setReplaceData] = useState(false);
    const [toolModified, setToolModified] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [toolDelete, setToolDelete] = useState('');
    const handleDelClose = () => setShowDel(false);
    const handleDelShow = () => setShowDel(true);

    useEffect(() => {
        if (id) {
            setToolId(id);
            loadTool();
            loadToolDataList(0);
        }

        return () => {
            setToolTitle('');
            setToolName('');
            setToolDescription('');
            setToolParameters('');
            setToolRequestUrl('');
            setToolRequestOptions('');
            setToolDataList([]);
        }
    }, [token]);

    const loadTool = () => {
        getTool(token, toolId).then(r => {
            if (!r.tool || !r.tool.id) {
                console.log(r);
                setError(r);
            }
            setToolTitle(r.tool.title);
            setToolName(r.tool.name);
            setToolDescription(r.tool.description);
            setToolParameters(r.tool.parameters);
            setToolRequestUrl(r.tool.request_url);
            setToolRequestOptions(r.tool.request_options);
            setToolModified(false);
            const toolForm = document.getElementById("ToolForm");
            toolForm.addEventListener("input", function () {
                if (!toolModified) {
                    setToolModified(true);
                }
            });
        });
    };

    const onSubmit = async (redirect, createNew = false) => {
        setError();

        const data = {
            title: toolTitle,
            name: toolName,
            description: toolDescription,
            parameters: toolParameters,
            request_url: toolRequestUrl,
            request_options: toolRequestOptions,
        };
        if (toolId) {
            await updateTool(token, toolId, data);
            loadTool();
        } else {
            await createTool(token, data).then(r => {
                if (!r.tool || !r.tool.id) {
                    console.log(r);
                    setError(r);
                }
                setToolId(r.tool.id);

                return history.push(`/tools/tool/${r.tool.id}`);
            });
        }

        if (redirect) {
            if (createNew) {
                return history.push(`/tools?action=createTool`);
            } else {
                return history.push(`/tools`);
            }
        }
    };

    const removeTool = (toolId) => {
        setError();
        delTool(token, toolId).then(r => {
            console.log(r);
            history.push(`/tools`);
        });
    };

    const loadToolDataList = (offset, limit = null) => {
        getToolDataList(token, toolId, limit || settings.items, offset).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setTotal(r.total || toolDataList.length);
            setToolDataList(offset ? toolDataList.concat(r.list || []) : (r.list || []));
        });
    };

    const getMoreItems = () => {
        loadToolDataList(toolDataList.length);
    };

    const removeToolData = (toolDataId) => {
        setError();
        if (window.confirm("Do you really want to delete this data?")) {
            delToolData(token, toolId, toolDataId).then(r => {
                console.log(r);
                setToolDataList(toolDataList.filter(item => item.id != toolDataId));
            });
        }
    };

    const onUploadFileCsv = async (e) => {
        setError();

        const fileInput = document.getElementById('fileImport');
        const formData = new FormData();

        e.target.disabled = true;

        formData.append("importFileCsv", selectedFileCsv, selectedFileCsv.name);

        await uploadFileCsv(token, toolId, formData, replaceData)
            .then(r => {
                if (r && r.imported) {
                    fileInput.value = null;
                    loadToolDataList(0);
                }
            });
    };

    const onDownloadFileCsv = () => {
        setError();
        prepareExportFileCsv(token, toolId).then(r => {
            console.log(r);
            if (r && r.export) {
                const a = document.createElement('a');
                a.target = "_blank"
                a.href = `${toolsUrl}/${toolId}/data/download/${r.export}`;
                a.download = r.export;
                a.click();
            }
        })
    };

    const toolPageTitle = toolId ? `Function "${toolTitle}"` : `Add New Function ${toolTitle ? `"${toolTitle}"` : ''}`;

    return (<>
        <Breadcrumb>
            <Breadcrumb.Item href="#/tools">Functions</Breadcrumb.Item>
            <Breadcrumb.Item active>{toolPageTitle}</Breadcrumb.Item>
            <Breadcrumb.Item href={`#/tools/tool/${toolId}/sandbox`}>Sandbox</Breadcrumb.Item>
        </Breadcrumb >
        {
            error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )
        }

        <h2 className="my-2 text-center">
            {toolPageTitle}
        </h2>

        <Alert variant="danger" className="mt-4" style={{ display: `${validationError ? 'block' : 'none'}` }}>
            {validationError}
        </Alert>

        <Form id="ToolForm">
            <h4 className="mt-2">Title *</h4>
            <Form.Group className="mb-1">
                <Form.Control type="text" placeholder="Enter name" value={toolTitle} onChange={e => {
                    setToolTitle(e.target.value);
                    setToolName(e.target.value.toLowerCase().replaceAll(' ', '_'));
                }} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Control type="text" value={toolName} disabled />
            </Form.Group>

            <h4 className="mt-2">Description *</h4>
            <Form.Group className="mb-2">
                <Form.Control
                    as="textarea"
                    placeholder="Enter description"
                    value={toolDescription}
                    onChange={e => setToolDescription(e.target.value)}
                    rows={1}
                />
            </Form.Group>

            {/* <h4 className="mt-2">Parameters *</h4>
            <Form.Group className="mb-2">
                <Form.Control
                    as="textarea"
                    placeholder="Enter parameters"
                    value={toolParameters}
                    onChange={e => setToolParameters(e.target.value)}
                    rows={8}
                />
                <Button
                    variant="secondary"
                    className="ms-0 me-1 my-1"
                    size="sm"
                    onClick={e => {
                        try {
                            setToolParameters(JSON.stringify(JSON.parse(toolParameters), undefined, 4));
                        } catch (ex) {
                            console.log('json');
                        }
                    }}
                >
                    Beautify
                </Button>
            </Form.Group> */}

            {/* <h4 className="mt-2">Request URL</h4>
            <Form.Group className="mb-2">
                <Form.Control type="text" placeholder="Enter URL" value={toolRequestUrl} onChange={e => setToolRequestUrl(e.target.value)} />
            </Form.Group>

            <h4 className="mt-2">Request Options</h4>
            <Form.Group className="mb-2">
                <Form.Control
                    as="textarea"
                    placeholder="Enter options"
                    value={toolRequestOptions}
                    onChange={e => setToolRequestOptions(e.target.value)}
                    rows={8}
                />
                <Button
                    variant="secondary"
                    className="ms-0 me-1 my-1"
                    size="sm"
                    onClick={e => {
                        try {
                            setToolRequestOptions(JSON.stringify(JSON.parse(toolRequestOptions), undefined, 4));
                        } catch (ex) {
                            console.log('json');
                        }
                    }}
                >
                    Beautify
                </Button>
            </Form.Group> */}

            <div className="d-flex">
                <Button
                    variant="secondary"
                    className="me-1 my-1"
                    size="sm"
                    onClick={e => {
                        return history.push(`/tools`);
                    }}
                >
                    &lt; Close
                </Button>
                {toolId ? (
                    <>
                        <Button
                            variant="danger"
                            className="me-1 my-1 ms-auto"
                            onClick={e => {
                                handleDelShow();
                            }}
                            size="sm"
                        >
                            Remove
                        </Button>
                        <Modal show={showDel} onHide={handleDelClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Remove the Tool</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                You want to delete this Tool. This is irreversible. Please type the word "DELETE" in the field to confirm.
                                <Form.Group className="mb-2">
                                    <Form.Control type="text" placeholder="DELETE" value={toolDelete} onChange={e => setToolDelete(e.target.value)} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={e => removeTool(toolId)} disabled={toolDelete != 'DELETE'}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : ''}
                <Button
                    variant="primary"
                    className="me-1 my-1 ms-auto"
                    onClick={e => onSubmit(true, false)}
                    size="sm"
                >
                    &lt; Save and Close
                </Button>
                <Button
                    variant="primary"
                    className="mx-1 my-1"
                    onClick={e => onSubmit(false, false)}
                    size="sm"
                >
                    Save Changes
                </Button>
            </div>
            {/* )} */}
        </Form>

        {
            toolDataList && toolId ? (
                <small>
                    <InfiniteScroll
                        dataLength={toolDataList.length}
                        next={getMoreItems}
                        hasMore={total > toolDataList.length}
                        loader={'...'}
                        endMessage={toolDataList.length ? '' : ''}
                    >
                        <h4 className="mt-4 mb-0">
                            Tool Data List
                        </h4>
                        <h6 className="mt-0">
                            <Badge bg="primary" className="me-1 my-1">Total: {total}</Badge>
                        </h6>

                        <Stack direction="horizontal">
                            <FormControl
                                id="fileImport"
                                type="file"
                                className="me-1 my-1 w-25"
                                size="sm"
                                onChange={e => setSelectedFileCsv(e.target.files[0])}
                                accept=".csv"
                            />
                            <Form.Check
                                inline
                                id="replaceData"
                                type="switch"
                                className="my-1 ms-3 me-1"
                                onChange={e => setReplaceData(e.target.checked)}
                                checked={replaceData}
                                style={{ fontSize: '1.25rem' }}
                            />
                            <Form.Label htmlFor="replaceData" className="my-1 ms-1 me-3">Replace</Form.Label>
                            <Button className="my-1 mx-1" size="sm" disabled={!selectedFileCsv} onClick={e => onUploadFileCsv(e)}>Import Data</Button>
                            <Button className="ms-auto my-1" size="sm" disabled={!toolDataList.length} onClick={onDownloadFileCsv}>Export Data</Button>
                        </Stack>

                        <Table striped bordered hover size="sm" className="align-middle">
                            <thead>
                                <tr key="0" className="align-middle">
                                    <th key="id">ID</th>
                                    <th key="key"><strong>Key</strong></th>
                                    <th key="tags">Tags</th>
                                    <th key="tags">Description</th>
                                    <th key="key"><strong>Value</strong></th>
                                    <th key="tags">Created</th>
                                    <th key="tags">Modified</th>
                                    <th>
                                        <Link to={`/tools/tool/${toolId}/data`}>
                                            <Button variant="outline-success" size="sm" className="mx-1 my-1" title="Add New Data ...">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </Link>
                                    </th>
                                </tr>
                            </thead>
                            {toolDataList.length ? (
                                <tbody>
                                    {toolDataList.map((data, index) => {

                                        return (
                                            <tr key={data.id} id={data.id}>
                                                <td key={`id-${data.id}`} className="text-right">
                                                    {data.id}
                                                </td>
                                                <td key={`key-${data.id}`}>
                                                    <small><strong>{data.key}</strong></small>
                                                </td>
                                                <td key={`tags-${data.id}`}>
                                                    {data.tags}
                                                </td>
                                                <td key={`description-${data.id}`}>
                                                    {data.description}
                                                </td>
                                                <td key={`value-${data.id}`}>
                                                    <small><strong>{data.value}</strong></small>
                                                </td>
                                                <td key={`created-${data.id}`} className="text-right">
                                                    {data.created}
                                                </td>
                                                <td key={`modified-${data.id}`} className="text-right">
                                                    {data.modified}
                                                </td>
                                                <td key={`actions-${data.id}`}>
                                                    <Link to={`/tools/tool/${toolId}/data/${data.id}`}>
                                                        <Button variant="outline-primary" size="sm" className="mx-1 my-1"
                                                            title="Edit Data Line"
                                                        >
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </Button>
                                                    </Link>
                                                    <Button variant="outline-danger" size="sm"
                                                        onClick={e => removeToolData(data.id)} className="mx-1 my-1"
                                                        title="Remove Data Line"
                                                    >
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (<tbody></tbody>)}
                        </Table>

                    </InfiniteScroll>
                </small>
            ) : ''
        }
    </>);
}
