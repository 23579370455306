import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function MainChart(props) {
    const { labels, data } = props;

    const statLabels = labels;

    const statOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        // stacked: false,
        // plugins: {
        //     title: {
        //         display: true,
        //         text: 'Statistics Multi Axis Chart',
        //     },
        // },
        scales: {
            y1: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            // y2: {
            //     type: 'linear',
            //     display: true,
            //     position: 'right',
            //     grid: {
            //         drawOnChartArea: false,
            //     },
            // },
            x1: {
                display: true,
                position: 'top',
            },
            // x2: {
            //     display: true,
            //     position: 'bottom',
            // },
        },
    };

    const statData = {
        labels: statLabels,
        datasets: [
            {
                label: 'Price',
                data: data,
                backgroundColor: '#48b9a2',
                borderColor: '#48b9a2',
                yAxisID: 'y1',
                xAxisID: 'x1',
                lineTension: 0.25,
            },
            // {
            //     label: 'Comprehension',
            //     data: data.map(s => s.comprehension),
            //     backgroundColor: 'purple',
            //     borderColor: 'purple',
            //     yAxisID: 'y2',
            //     xAxisID: 'x2',
            //     lineTension: 0.25,
            // },
        ],
    };

    return <Bar
        options={statOptions}
        data={statData}
    />;
}