import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Badge, Breadcrumb, Button, Container, Form, Modal, Table } from "react-bootstrap";

import useToken from "../../App/useToken";
import useUser from "../../App/useUser";
import useSettings from "../../App/useSettings";

import { companiesUrl } from "../../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";

async function getCompanies(token, limit, offset) {
  return fetch(`${companiesUrl}?limit=${limit || 0}&offset=${offset || 0}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function createCompany(token, data) {
  return fetch(`${companiesUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function updateCompany(token, companyId, data) {
  return fetch(`${companiesUrl}/${companyId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((data) => data.json())
    .catch((e) => e);
}

const Companies = () => {
  const history = useHistory();
  const location = useLocation();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [models, setModels] = useState([]);
  const { settings, setSettings } = useSettings();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "create" });

  useEffect(() => {
    loadModels(token, settings.items, offset);
    return () => setModels([]);
  }, [user, token]);

  const loadModels = (token, limit = 10, offset) => {
    setError();
    getCompanies(token, limit, offset)
      .then((r) => {
        console.log("getCompanies", r);
        if (!r.list) {
          setError(r);
          return;
        }
        const _models = offset ? models.concat(r.list || []) : r.list || [];
        setModels(_models);
        setTotal(r.total || models.length);
      })
      .catch((e) => console.log(e));
  };

  const getMoreItems = () => {
    loadModels(token, settings.items, models.length);
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, title: "" });
  };

  const onSave = () => {
    setError();
    const data = { title: modal.title };
    if (modal.mode === "create") {
      createCompany(token, data)
        .then((r) => {
          console.log("createCompany", r);
          loadModels(token, settings.items);
          handleCloseModal();
        })
        .catch((e) => console.log(e));
    }
    if (modal.mode === "edit") {
      updateCompany(token, modal.id, data)
        .then((r) => {
          console.log("updateCompany", r);
          loadModels(token, settings.items);
          handleCloseModal();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Companies</Breadcrumb.Item>
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <h2 className="my-4 text-center">Companies</h2>

      <Button
        className="ms-4 my-1"
        size="sm"
        onClick={() => {
          setModal((ps) => ({ ...ps, isOpen: true, mode: "create" }));
        }}
      >
        Create Company
      </Button>

      {models && (
        <InfiniteScroll
          dataLength={models.length} //This is important field to render the next data
          next={getMoreItems}
          hasMore={total > models.length}
          // loader={<Spinner accessibilityLabel="..." size="large" />}
          loader={""}
          endMessage={models.length ? "..." : ""}
        >
          <Table striped bordered hover size="sm" className="align-middle">
            <thead>
              <tr key="0" className="align-middle">
                <th>ID</th>
                <th>Title</th>
                <th>Users</th>
                <th>Created</th>
                <th>Modified</th>
                <th>Supervisor ID</th>
                <th>API KEY</th>
              </tr>
            </thead>
            <tbody>
              {!!models.length &&
                models.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td title="Edit company">
                      <Link
                        onClick={() =>
                          setModal((ps) => ({ ...ps, isOpen: true, mode: "edit", id: item.id, title: item.title }))
                        }
                        to="#"
                      >
                        {item.title}
                      </Link>
                    </td>
                    <td title="Go to users by this company">
                      <Link to={`/users?company=${item.id}`}> {item.users_count}</Link>
                    </td>
                    <td>{item.created}</td>
                    <td>{item.modified}</td>
                    <td>{item.supervisor_id}</td>
                    <td>{item.api_key}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}

      <Modal show={modal.isOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.mode === "create" ? "Create" : "Update"} Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Title"
              value={modal.title}
              onChange={(e) => setModal((ps) => ({ ...ps, title: e.target.value }))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onSave}>
            {modal.mode === "create" ? "Create" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Companies;
