import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Badge, Breadcrumb, Button, Container, Form, Modal, Table } from "react-bootstrap";

import useToken from "../../App/useToken";
import useUser from "../../App/useUser";
import useSettings from "../../App/useSettings";

import { usersUrl } from "../../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { removeUnchanged } from "../../../utils/utils";

async function getUsers(token, companyId, limit, offset) {
  return fetch(`${usersUrl}?${companyId ? "company=" + companyId : ""} limit=${limit || 0}&offset=${offset || 0}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function getUser(token, userId) {
  return fetch(`${usersUrl}/${userId ? userId : "me"}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function createUser(token, data) {
  return fetch(`${usersUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function updateUser(token, userId, data) {
  return fetch(`${usersUrl}/${userId ? userId : "me"}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((data) => data.json())
    .catch((e) => e);
}

const Users = () => {
  const history = useHistory();
  const location = useLocation();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [models, setModels] = useState([]);
  const [statuses, setStatuses] = useState({});
  const { settings, setSettings } = useSettings();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState({ isOpen: false, mode: "create", userCurrent: {} });

  const userRef = useRef();

  useEffect(() => {
    loadModels(token, settings.items, offset);
    return () => setModels([]);
  }, [user, token]);

  const loadModels = (token, limit = 10, offset) => {
    setError();
    const query = new URLSearchParams(location.search);

    const companyId = query.get("company");
    getUsers(token, companyId, limit, offset)
      .then((r) => {
        console.log("getUsers", r);
        if (!r.list) {
          setError(r);
          return;
        }
        const _models = offset ? models.concat(r.list || []) : r.list || [];
        setModels(_models);
        setTotal(r.total || models.length);
      })
      .catch((e) => console.log(e));
  };

  const getMoreItems = () => {
    loadModels(token, settings.items, models.length);
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, userCurrent: {} });
    userRef.current = null;
  };

  const onSave = () => {
    setError();
    const data = removeUnchanged(modal.userCurrent, userRef.current || {}); 

    if (modal.mode === "create") {
      createUser(token, data)
        .then((r) => {
          // console.log("createUser", r);
          loadModels(token, settings.items);
          handleCloseModal();
        })
        .catch((e) => console.log(e));
    }
    if (modal.mode === "edit") {
      updateUser(token, modal.id, data)
        .then((r) => {
          // console.log("updateUser", r);
          loadModels(token, settings.items);
          handleCloseModal();
        })
        .catch((e) => console.log(e));
    }
  };

  const onChangeModalForm = (e) => {
    setModal((ps) => ({ ...ps, userCurrent: { ...ps.userCurrent, [e.target.name]: e.target.value } }));
  };

  const getEditedUser = (userID) => {
    getUser(token, userID).then((r) => {
      console.log(r);

      setModal((ps) => ({ ...ps, isOpen: true, mode: "edit", userCurrent: r }));
      userRef.current = r;
    });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <h2 className="my-4 text-center">Users</h2>

      <Button
        className="ms-4 my-1"
        size="sm"
        onClick={() => {
          setModal((ps) => ({ ...ps, isOpen: true, mode: "create" }));
        }}
      >
        Create New User
      </Button>

      {models && (
        <InfiniteScroll
          dataLength={models.length} //This is important field to render the next data
          next={getMoreItems}
          hasMore={total > models.length}
          // loader={<Spinner accessibilityLabel="..." size="large" />}
          loader={""}
          endMessage={!!models.length ? "..." : ""}
        >
          <Table striped bordered hover size="sm" className="align-middle">
            <thead>
              <tr key="0" className="align-middle">
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Assigned Models</th>
                <th>Last activity</th>
                <th>Registered</th>
                <th>Is Admin</th>
                <th>Company ID</th>
                <th>Personal model ID</th>
                <th>Personal voice ID</th>
                <th>External user ID</th>
              </tr>
            </thead>
            <tbody>
              {models.length
                ? models.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.email}</td>
                      <td>{item.assigned_models_count}</td>
                      <td>{item.last_activity}</td>
                      <td>{item.registered}</td>
                      <td>{item.is_admin}</td>
                      <td>{item.company_id}</td>
                      <td>{item.personal_model_id}</td>
                      <td>{item.personal_voice_id}</td>
                      <td>{item.external_user_id}</td>
                      <td>
                        <Link to={`#`}>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="mx-1 my-1"
                            onClick={() => getEditedUser(item.id)}
                          >
                            Edit
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}

      <Modal show={modal.isOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.mode === "create" ? "Create" : "Update"} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              placeholder="First Name"
              value={modal.userCurrent.first_name}
              onChange={onChangeModalForm}
            />{" "}
          </Form.Group>{" "}
          <Form.Group className="mb-2">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={modal.userCurrent.last_name}
              onChange={onChangeModalForm}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Email"
              value={modal.userCurrent.email}
              onChange={onChangeModalForm}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Role</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="is_admin"
              onChange={onChangeModalForm}
              value={modal.userCurrent.is_admin ? modal.userCurrent.is_admin : 0}
            >
              <option value={0}>User</option>
              <option value={1}>Admin</option>
              <option value={2}>Super Admin</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Margin</Form.Label>
            <Form.Control
              type="text"
              name="personal_model_id"
              placeholder="Personal model ID"
              value={modal.userCurrent.margin}
              onChange={onChangeModalForm}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Factor</Form.Label>
            <Form.Control
              type="text"
              name="personal_voice_id"
              placeholder="Personal voice ID"
              value={modal.userCurrent.factor}
              onChange={onChangeModalForm}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onSave}>
            {modal.mode === "create" ? "Create" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
