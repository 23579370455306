import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Card, Col, Container, Form, Row, Table, Tabs, Tab } from 'react-bootstrap';

import useUser from '../App/useUser';
import useToken from '../App/useToken';
import { usersUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';

import MainChart from './MainChart';

async function getModelUseBalance(token, customerId) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/balance/use`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModelTrainingBalance(token, customerId) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/balance/training`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModelUseStatistics(token, customerId, limitUse, offsetUse) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/statistics/use?limit=${limitUse || 0}&offset=${offsetUse || 0}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModelTrainingStatistics(token, customerId, limitTraining, offsetTraining) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/statistics/training?limit=${limitTraining || 0}&offset=${offsetTraining || 0}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getCustomers(token) {

    return fetch(`${usersUrl}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getUserMargins(token, customerId) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/margins`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function updateUserMargins(token, customerId, userFactor /* , userMargin */) {

    return fetch(`${usersUrl}/${customerId ? `${customerId}` : 'me'}/margins`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            factor: userFactor,
        }),
    })
        .then(data => data.json());
}

export default function Statistics(props) {
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const { customerId: id } = useParams();
    const [customerId, setCustomerId] = useState(id || user.id);
    const [customers, setCustomers] = useState();
    const [userFactor, setUserFactor] = useState(1);
    // const [userMargin, setUserMargin] = useState(0);
    const [balanceUse, setBalanceUse] = useState();
    const [balanceTraining, setBalanceTraining] = useState();
    const [statisticsUse, setStatisticsUse] = useState([]);
    const [statisticsTraining, setStatisticsTraining] = useState([]);
    const [offsetUse, setOffsetUse] = useState(0);
    const [offsetTraining, setOffsetTraining] = useState(0);
    const [totalUse, setTotalUse] = useState(0);
    const [totalTraining, setTotalTraining] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadCustomers();
        loadModelUseBalance();
        loadModelTrainingBalance();
        loadModelUseStatistics();
        loadModelTrainingStatistics();
        loadUserMargins();

        return () => {
            setCustomers();
            setBalanceUse();
            setBalanceTraining();
            setStatisticsUse(null);
            setStatisticsTraining(null);
            setUserFactor(1);
        }
    }, [user, token]);

    useEffect(() => {
        if (customers && customers.length) {
            const currentCustomer = customers.filter(customer => customer.id == customerId)[0];
            setUserFactor(currentCustomer.factor);
            // setUserMargin(currentCustomer.margin);
        }
        loadModelUseBalance();
        loadModelTrainingBalance();
        loadModelUseStatistics();
        loadModelTrainingStatistics();
        loadUserMargins();

        return () => {
            setUserFactor(1);
            setBalanceUse();
            setBalanceTraining();
            setStatisticsUse(null);
            setStatisticsTraining(null);
        }
    }, [customerId]);

    const loadUserMargins = () => {
        setError();
        getUserMargins(token, customerId).then(r => {
            if (r && typeof r.factor == 'undefined') {
                console.log(r);
                setError(r);
            }
            setUserFactor(r.factor);
        });
    }

    const loadCustomers = () => {
        // setError();
        getCustomers(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            }
            setCustomers(r.list);
        });
    }

    const loadModelUseBalance = () => {
        setError();
        getModelUseBalance(token, customerId || user.id).then(r => {
            if (r && typeof r.balance == 'undefined') {
                console.log(r);
                setError(r);
            }
            setBalanceUse(r.balance);
        });
    }

    const loadModelTrainingBalance = () => {
        setError();
        getModelTrainingBalance(token, customerId || user.id).then(r => {
            if (r && typeof r.balance == 'undefined') {
                console.log(r);
                setError(r);
            }
            setBalanceTraining(r.balance);
        });
    }

    const loadModelUseStatistics = (offsetUse = 0, limitUse = null) => {
        // setError();
        getModelUseStatistics(token, customerId || user.id, limitUse, offsetUse).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setStatisticsUse(offsetUse ? statisticsUse.concat(r.list || []) : (r.list || []));
            setTotalUse(r.total || statisticsUse.length);
        });
    }

    const loadModelTrainingStatistics = (offsetTraining = 0, limitTraining = null) => {
        // setError();
        getModelTrainingStatistics(token, customerId || user.id, limitTraining, offsetTraining).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setStatisticsTraining(offsetTraining ? statisticsTraining.concat(r.list || []) : (r.list || []));
            setTotalTraining(r.total || statisticsTraining.length);
        });
    }

    const getMoreUseItems = () => {
        loadModelUseStatistics(statisticsUse.length);
    }

    const getMoreTrainingItems = () => {
        loadModelTrainingStatistics(statisticsTraining.length);
    }

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Billing</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <h2 className="my-4 text-center">Billing</h2>
            <>
                {user.is_admin ? (<>
                    <h4 className="my-2">
                        Customer
                    </h4>
                    <Form.Select
                        className="my-2"
                        value={customerId}
                        onChange={e => setCustomerId(e.target.value)}
                    >
                        {customers ? customers
                            .map(customer => (
                                <option value={customer.id}>{customer.first_name} {customer.last_name} &lt;{customer.email}&gt;</option>
                            )) : ''}
                    </Form.Select>
                </>) : ''}
                <h4 className="mt-4">Margin</h4>
                <Form.Group className="mb-2">
                    <Row>
                        <Col>
                            <Form.Label>General factor</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control type="number" min="0" step="0.01" placeholder="Enter factor" value={userFactor} onChange={e => setUserFactor(e.target.value)} />
                        </Col>
                        {/* <Col>
                            <Form.Control type="number" min="0" step="0.01" placeholder="Enter margin" value={userMargin} onChange={e => setUserMargin(e.target.value)} />
                        </Col> */}
                        <Col>
                            <Button className="ms-4 my-1" size="sm" onClick={() => {
                                setError();
                                updateUserMargins(token, customerId, userFactor).then(r => {
                                    if (!r.factor) {
                                        console.log(r);
                                        setError(r);
                                    }
                                    loadUserMargins();
                                    loadModelUseBalance();
                                    loadModelTrainingBalance();
                                    loadModelUseStatistics();
                                    loadModelTrainingStatistics();
                                });
                            }}>Update</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <h4 className="mt-4 mb-2">Total Balance:</h4>
                <h6 className="my-2">{`$${Math.round(100 * balanceUse + 100 * balanceTraining) / 100 || 0}`}</h6>
                <h4 className="mt-4 mb-2">Statistics:</h4>
            </>
            <Tabs defaultActiveKey="use" id="balance" className="mb-2">
                <Tab eventKey="use" title={`Model Usage ( $${balanceUse || 0} )`}>
                    {statisticsUse && statisticsUse.length ? (
                        <>
                            <h4 className="my-2">Use Balance:</h4>
                            <h6 className="my-2">{`$${balanceUse || 0}`}</h6>
                            <MainChart
                                labels={statisticsUse.map(s => s.h_created)}
                                data={statisticsUse.map(s => s.h_price)}
                            />
                            <InfiniteScroll
                                dataLength={statisticsUse.length} //This is important field to render the next data
                                next={getMoreUseItems}
                                hasMore={totalUse > statisticsUse.length}
                                // loader={<Spinner accessibilityLabel="..." size="large" />}
                                loader={''}
                                endMessage={statisticsUse.length ?
                                    ('') :
                                    ''
                                }
                            >
                                <Table striped bordered hover size="sm" className="small">
                                    <thead className="align-middle">
                                        <tr key="0">
                                            <th className="align-middle">Date</th>
                                            {/* <th className="align-middle">Tokens</th> */}
                                            <th className="align-middle">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statisticsUse.map(s => {

                                            return (
                                                <tr>
                                                    <td><small>{s.h_created}</small></td>
                                                    {/* <td>{s.h_tokens}</td> */}
                                                    <td>{s.h_price}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </>
                    ) : (
                        ''
                    )
                    }
                </Tab>
                <Tab eventKey="training" title={`Model Training ( $${balanceTraining || 0} )`}>
                    {statisticsTraining && statisticsTraining.length ? (
                        <>
                            <h4 className="my-2">Training Balance:</h4>
                            <h6 className="my-2">{`$${balanceTraining || 0}`}</h6>
                            <MainChart
                                labels={statisticsTraining.map(s => s.v_created)}
                                data={statisticsTraining.map(s => s.v_price)}
                            />
                            <InfiniteScroll
                                dataLength={statisticsTraining.length} //This is important field to render the next data
                                next={getMoreTrainingItems}
                                hasMore={totalTraining > statisticsTraining.length}
                                // loader={<Spinner accessibilityLabel="..." size="large" />}
                                loader={''}
                                endMessage={statisticsTraining.length ?
                                    ('') :
                                    ''
                                }
                            >
                                <Table striped bordered hover size="sm" className="small">
                                    <thead className="align-middle">
                                        <tr key="0">
                                            <th className="align-middle">Date</th>
                                            {/* <th className="align-middle">Tokens</th> */}
                                            <th className="align-middle">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statisticsTraining.map(s => {

                                            return (
                                                <tr>
                                                    <td><small>{s.v_created}</small></td>
                                                    {/* <td>{s.v_tokens}</td> */}
                                                    <td>{s.v_price}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </>
                    ) : (
                        ''
                    )
                    }
                </Tab>
            </Tabs>
        </>);
}