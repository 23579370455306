import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button, Container, Form, Row } from 'react-bootstrap';

import { registerUrl } from '../../utils/api';

function registerUser(credentials) {
    return fetch(registerUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function Register({ setToken, setUser }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [error, setError] = useState(false);

    const handleSubmit = e => {
        setError();
        e.preventDefault();
        if (confirmPassword === password) {
            registerUser({
                email,
                password,
                first_name: firstName,
                last_name: lastName,
            }).then(r => {
                if (!r.auth) {
                    console.log(r);
                    setError(r);
                }
                setToken(r.auth || null);
                setUser(r.user || null);
            });

            return true;
        }

        return false;
    }

    return (
        <>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <h2 className="my-4 text-center">Registration</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3 row">
                    <Form.Label>Fist name *</Form.Label>
                    <Form.Control type="text" onChange={e => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 row">
                    <Form.Label>Last name *</Form.Label>
                    <Form.Control type="text" onChange={e => setLastName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 row">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control type="text" name="email" onChange={e => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 row">
                    <Form.Label>Password *</Form.Label>
                    <Form.Control type="password" name="password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 pb-3 row">
                    <Form.Label>Confirm password *</Form.Label>
                    <Form.Control type="password" name="confirm" onChange={e => setConfirmPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 pt-3 row">
                    <Button type="submit" className="btn-success">Sign Up</Button>
                </Form.Group>
            </Form>
        </>
    )
}

Register.propTypes = {
    setToken: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
};