import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Container, Form, FormControl, InputGroup, ProgressBar, Stack, Table } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { flowsUrl, modelsUrl, usersUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Autosuggest from 'react-autosuggest';

async function getFlow(token, flowId) {

    return fetch(`${flowsUrl}/${flowId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getCustomers(token) {

    return fetch(`${usersUrl}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModels(token) {

    return fetch(`${modelsUrl}?limit=1000&offset=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createFlow(token, data) {

    return fetch(`${flowsUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateFlow(token, flowId, data) {

    return fetch(`${flowsUrl}/${flowId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delFlow(token, flowId) {

    return fetch(`${flowsUrl}/${flowId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

// TODO

export default function Flow(props) {
    const history = useHistory();
    const location = useLocation();
    let { flowId: id } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const { settings, setSettings } = useSettings();

    const [flowId, setFlowId] = useState(id);
    const [flowTitle, setFlowTitle] = useState('');
    const [flowUsers, setFlowUsers] = useState([]);
    const [flowModels, setFlowModels] = useState([]);

    // Managing Users in the Flow
    const [customers, setCustomers] = useState([]);

    // Managing Models in the Flow
    const [models, setModels] = useState([]);
    const [newModelId, setNewModelId] = useState();

    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            setFlowId(id);
            loadFlow();
        }
        loadCustomers();
        loadModels();

        return () => {
            setFlowTitle('');
            setFlowUsers([]);
            setFlowModels([]);
        }
    }, [token]);

    // useEffect(() => {
    //     if (newModelId) {
    //         setFlowModels([...flowModels, newModelId]);
    //         setNewModelId();
    //     }
    // }, [newModelId]);

    const loadFlow = () => {
        // setError();
        getFlow(token, flowId).then(r => {
            if (!r.flow || !r.flow.id) {
                console.log(r);
                setError(r);
            }
            setFlowTitle(r.flow.title);
            setFlowUsers(r.flow.users ? r.flow.users : []);
            setFlowModels(JSON.parse(r.flow.models || []));
        });
    };

    const loadCustomers = () => {
        // setError();
        getCustomers(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            }
            setCustomers(r.list.length ? r.list.map(customer => customer) : []);
        });
    }

    const loadModels = () => {
        // setError();
        getModels(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            }
            setModels(r.list.length ? r.list.map(model => model) : []);
        });
    }

    const onSubmit = async (redirect, createNew = false) => {
        setError();
        const data = {
            title: flowTitle,
            users: flowUsers,
            models: JSON.stringify(flowModels),
            user_id: user.id,
        };
        if (flowId) {
            await updateFlow(token, flowId, data);
            loadFlow();
        } else {
            await createFlow(token, data).then(r => {
                if (!r.flowId) {
                    console.log(r);
                    setError(r);
                }
                setFlowId(r.flowId);
                history.push(`/flows/flow/${r.flowId}`);
            });
        }
        if (redirect) {
            if (createNew) {
                history.push(`/flows?action=createFlow`);
            } else {
                history.push(`/flows`);
            }
        }
    };

    const removeFlow = (flowId) => {
        setError();
        if (window.confirm("Do you really want to delete this flow?")) {
            delFlow(token, flowId).then(r => {
                console.log(r);
                history.push(`/flows`);
            });
        }
    };

    const removeFlowModel = (index) => {
        setError();
        if (window.confirm("Do you really want to remove this model from the flow?")) {
            setFlowModels([...flowModels.filter((item, mIndex) => mIndex !== index)]);
        }
    };

    const flowPageTitle = flowId ? `Flow "${flowTitle}"` : `Add New Flow ${flowTitle ? `"${flowTitle}"` : ''}`;

    const getCustomerById = customerId => customers.filter(customer => customer.id == customerId)[0];

    const renderCustomer = customer => customer ? `${customer.first_name || ''} ${customer.last_name || ''} <${customer.email}>` : null;

    const renderCustomerTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
        return (
            <span key={key} {...other}>
                {renderCustomer(customers.filter(customer => customer.id == tag)[0])}
                {!disabled &&
                    <a className={classNameRemove} onClick={(e) => onRemove(key)} />
                }
            </span>
        )
    }

    const autocompleteRenderInput = ({ addTag, ...props }) => {
        const handleOnChange = (e, { newValue, method }) => {
            if (method === 'enter') {
                e.preventDefault();
            } else {
                props.onChange(e);
            }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
        const inputLength = inputValue.length;

        const suggestions = customers.filter(customer => !flowUsers.includes(customer.id) &&
            (
                `${customer.first_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.last_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.email}`.toLowerCase().slice(0, inputLength) === inputValue
            )
        );

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={value => renderCustomer(value)}
                getSuggestionValue={suggestion => suggestion.id}
                renderSuggestion={suggestion => <span>{renderCustomer(suggestion)}</span>}
                inputProps={{ ...props, onChange: handleOnChange }}
                onSuggestionSelected={(e, { suggestion }) => {
                    addTag(suggestion.id);
                }}
                onSuggestionsClearRequested={() => { }}
                onSuggestionsFetchRequested={() => { }}
            />
        )
    }

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item href="#/flows">Flows</Breadcrumb.Item>
                <Breadcrumb.Item active>{flowPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/flows/flow/${flowId}/sandbox`}>Sandbox</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}

            <h2 className="my-2 text-center">
                {flowPageTitle}
            </h2>
            <Form>
                <h4 className="mt-2">Title</h4>
                <Form.Group className="mb-2">
                    <Form.Control type="text" placeholder="Enter title" value={flowTitle} onChange={e => setFlowTitle(e.target.value)} />
                </Form.Group>
                {user.is_admin ? (
                    <>
                        <h4 className="mt-4">Users</h4>
                        <Form.Group className="mb-2">
                            {/* <Form.Label>Users</Form.Label> */}
                            <InputGroup>
                                <TagsInput
                                    renderInput={autocompleteRenderInput}
                                    renderTag={renderCustomerTag}
                                    value={flowUsers}
                                    onChange={values => setFlowUsers(values.filter(value => customers.map(customer => customer.id).includes(value)))}
                                    disabled={!user.is_admin}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: user.is_admin ? 'Add ...' : '',
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                    </>
                ) : ''}
                <div className="d-flex">
                    <Link to={`/models`}>
                        <Button
                            variant="secondary"
                            className="me-1 my-1"
                            size="sm"
                        >
                            &lt; Close
                        </Button>
                    </Link>
                    <Button
                        variant="primary"
                        className="me-1 my-1 ms-auto"
                        onClick={e => onSubmit(true, false)}
                        size="sm"
                    >
                        &lt; Save and Close
                    </Button>
                    <Button
                        variant="primary"
                        className="mx-1 my-1"
                        onClick={e => onSubmit(false, false)}
                        size="sm"
                    >
                        Save Changes
                    </Button>
                </div>
            </Form>
            <h4 className="mt-4">Steps (Models)</h4>
            <Table striped bordered hover size="sm" className="align-middle">
                <thead>
                    <tr key="models" className="align-middle">
                        <th>Step</th>
                        {user.is_admin ? (<th>ID</th>) : ''}
                        <th>Model Title</th>
                        {user.is_admin ? (<th>Prompt Fields</th>) : ''}
                        {user.is_admin ? (<th>Completion Fields</th>) : ''}
                        <th>Created</th>
                        <th>Modified</th>
                        <th><span title="Trained Data">Applied Data</span></th>
                        <th><span title="Pending Data">Pending Data</span></th>
                        <th>Versions</th>
                        {user.is_admin ? (<th>Users</th>) : ''}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {flowModels && flowModels.length ? flowModels.map((flowModelId, flowModelIndex) => {
                        const currentModel = models.filter(model => model.id == flowModelId)[0];
                        return currentModel && (
                            <tr key={flowModelIndex}>
                                <td>{flowModelIndex + 1}</td>
                                {user.is_admin ? (<td>{flowModelId}</td>) : ''}
                                <td>
                                    {/* <Link to={`/models/model/${item.id}`}> */}
                                    {currentModel.title}
                                    {/* </Link> */}
                                </td>
                                {user.is_admin ? (<td>
                                    {(JSON.parse(currentModel.prompt || '') || []).
                                        map(promptField =>
                                            <Badge pill bg="secondary" className="mx-1 my-1">{promptField}</Badge>
                                        )}
                                </td>) : ''}
                                {user.is_admin ? (<td>
                                    {(JSON.parse(currentModel.completion || '') || []).
                                        map(completionField =>
                                            <Badge pill bg="secondary" className="mx-1 my-1">{completionField}</Badge>
                                        )}
                                </td>) : ''}
                                <td>{currentModel.created}</td>
                                <td>{currentModel.modified}</td>
                                <td>{currentModel.c_data - currentModel.c_pending}</td>
                                <td>{currentModel.c_pending}</td>
                                <td>{currentModel.c_versions}</td>
                                {user.is_admin ? (<td>{currentModel.c_users}</td>) : ''}
                                <td>
                                    <Button variant="outline-danger" size="sm" className="mx-1 my-1"
                                        onClick={e => removeFlowModel(flowModelIndex)}>
                                        Remove
                                    </Button>
                                </td>
                            </tr>
                        );
                    }) : ''}
                    <tr key="modelId-new">
                        <td>{flowModels.length + 1}</td>
                        <td colSpan={user.is_admin ? 10 : 6}>
                            <Form.Select
                                className="my-3"
                                value={newModelId}
                                onChange={e => {
                                    setFlowModels([...flowModels, e.target.value]);
                                    setNewModelId();
                                }}
                            >
                                <option disabled selected value="0">Select the Model</option>
                                {models ?
                                    models.map(model => (
                                        <option value={model.id}>({model.id}) {model.title}</option>
                                    )) :
                                    ''}
                            </Form.Select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </>);
}