import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Breadcrumb, Button, Form } from "react-bootstrap";

import useToken from "../App/useToken";
import useUser from "../App/useUser";
import useSettings from "../App/useSettings";

import { usersUrl } from "../../utils/api";
import { createNameFromProperty, removeUnchanged } from "../../utils/utils";

import styles from "./myaccount.module.css";

async function getUser(token) {
  return fetch(`${usersUrl}/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function updateUser(token, data) {
  return fetch(`${usersUrl}/me`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((data) => data.json())
    .catch((e) => e);
}

const unusableProps = ["deleted", "version", "id", "password"];

const MyAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const { settings, setSettings } = useSettings();

  const [error, setError] = useState(null);
  const [userAPI, setUserAPI] = useState({});

  const userRef = useRef();

  useEffect(() => {
    getUser(token).then((r) => {
      console.log(r);
      setUserAPI(r);
      userRef.current = r;
    });
  }, [user, token]);

  const onSave = () => {
    setError();
    const data = removeUnchanged(userAPI, userRef.current);
    updateUser(token, data)
      .then((r) => {
        // console.log("updateUser", r);
      })
      .catch((e) => console.log(e));
  };

  const onChangeModalForm = (e) => {
    setUserAPI((ps) => ({ ...ps, [e.target.name]: e.target.value }));
  };

  const userForm = userAPI ? Object.keys(userAPI).filter((prop) => !unusableProps.includes(prop)) : [];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>My Account</Breadcrumb.Item>
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <div className={styles.userForm}>
        {userForm.map((prop) => {
          if (prop === "is_admin") {
            return (
              <Form.Group className="mb-2" key={prop}>
                <Form.Label>Role</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name={prop}
                  onChange={onChangeModalForm}
                  value={userAPI[prop] ? userAPI[prop] : 0}
                >
                  <option value={0}>User</option>
                  <option value={1}>Admin</option>
                  <option value={2}>Super Admin</option>
                </Form.Select>
              </Form.Group>
            );
          }

          return (
            <Form.Group className="mb-2" key={prop}>
              <Form.Label>{createNameFromProperty(prop)}</Form.Label>
              <Form.Control
                type="text"
                name={prop}
                placeholder="First Name"
                value={userAPI[prop] || ""}
                onChange={onChangeModalForm}
              />
            </Form.Group>
          );
        })}
      </div>

      <Button variant="secondary" onClick={onSave}>
        Save
      </Button>
    </>
  );
};

export default MyAccount;
