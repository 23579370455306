import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Container, Table } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';

import { apiKeysUrl, docUrl } from '../../utils/api';

async function generateApiKey(token) {

    return fetch(apiKeysUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function ApiKeys(props) {
    const history = useHistory();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [apiKey, setApiKey] = useState();
    const { settings, setSettings } = useSettings();
    const [error, setError] = useState(null);

    useEffect(() => {
        setApiKey(user.api_key);

        return () => setApiKey();
    }, [user, token]);

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Api</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <h2 className="my-4 text-center">API Key</h2>
            {apiKey ? (
                <>
                    <h4 className="mt-4">API Key:</h4>
                    <h6 className="my-2">{apiKey}
                        <Button className="ms-4 my-1" size="sm" onClick={() => {
                            if (window.confirm("Are you sure to generate the new API key instead of old one?")) {
                                setError();
                                generateApiKey(token).then(r => {
                                    if (!r.api_key) {
                                        console.log(r);
                                        setError(r);
                                    }
                                    setApiKey(r.api_key);
                                    setUser({ ...user, api_key: r.api_key });
                                });
                            }
                        }}>Regenerate</Button>
                    </h6>
                    <h4 className="mt-4">API Handler for using the trained model:</h4>
                    <h6 className="my-2">{`POST https://api.strietzel.ai/v1/{ModelID}/q?api_key=${apiKey}`}</h6>
                    <h4 className="mt-4">API Handler for using the external translation model:</h4>
                    <h6 className="my-2">
                        <a href={`${docUrl}#model`}>
                            {`POST https://api.strietzel.ai/v1/models/{ModelID}/generate_subtitles?api_key=${apiKey}&from=en-US&to=ja`}
                        </a>
                    </h6>
                </>
            ) : (
                <h6>
                    <Button className="my-1" size="sm" onClick={() => {
                        setError();
                        generateApiKey(token).then(r => {
                            if (!r.api_key) {
                                console.log(r);
                                setError(r);
                            }
                            setApiKey(r.api_key);
                        });
                    }}>Generate</Button></h6>
            )}
        </>);
}