import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Badge, Breadcrumb, Button, Container, Form, Modal, Table } from "react-bootstrap";

import useToken from "../App/useToken";
import useUser from "../App/useUser";
import useSettings from "../App/useSettings";

import { modelsUrl } from "../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";

async function getModels(token, limit, offset) {
  return fetch(`${modelsUrl}?limit=${limit || 0}&offset=${offset || 0}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => e);
}

async function cloneModel(token, modelId) {
  return fetch(`${modelsUrl}/${modelId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => console.log(e));
}

async function delModel(token, modelId) {
  return fetch(`${modelsUrl}/${modelId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => console.log(e));
}

async function checkTrainingModel(token, modelId) {
  return fetch(`${modelsUrl}/${modelId}/check`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => {
      console.log(e);
    });
}

export default function Models(props) {
  const history = useHistory();
  const location = useLocation();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [models, setModels] = useState([]);
  const [statuses, setStatuses] = useState({});
  const { settings, setSettings } = useSettings();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const [showDel, setShowDel] = useState(false);
  const [modelDelete, setModelDelete] = useState("");
  const [modelDelId, setModelDelId] = useState();

  const handleDelClose = () => {
    setModelDelId();
    setModelDelete("");
    setShowDel(false);
  };
  const handleDelShow = (modelId) => {
    setShowDel(true);
    setModelDelete("");
    setModelDelId(modelId);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("action") == "createModel") {
      history.push(`/models/model`);
    }

    loadModels(token, settings.items, offset);

    return () => setModels([]);
  }, [user, token]);

  const loadModels = (token, limit, offset) => {
    setError();
    const _statuses = {};
    getModels(token, limit, offset)
      .then((r) => {
        if (!r.list) {
          console.log(r);
          setError(r);
        }
        const _models = offset ? models.concat(r.list || []) : r.list || [];
        setModels(_models);
        setTotal(r.total || models.length);
        // Promise.all(
        r.list.map(async (m, i) => {
          await checkTrainingModel(token, m.id).then((r) => {
            _statuses[m.id] = r && r.ai_fine_tune_status ? r.ai_fine_tune_status : "";
          });
        });
        // );
        setTimeout(
          () =>
            setModels(
              _models.map((model) => {
                return { ...model, status: _statuses[model.id] || "" };
              })
            ),
          2000
        );
      })
      .catch((e) => console.log(e));
  };

  const getMoreItems = () => {
    loadModels(token, settings.items, models.length);
  };

  const copyModel = (modelId) => {
    cloneModel(token, modelId).then((r) => {
      console.log(r);
      if (r && r.modelId) loadModels(token, settings.items, offset);
    });
  };

  const removeModel = (modelId) => {
    delModel(token, modelId).then((r) => {
      console.log(r);
      setModels(models.filter((item) => item.id != modelId));
    });
  };

  return (
    <>
      <Breadcrumb>
        {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
        <Breadcrumb.Item active>Models</Breadcrumb.Item>
        {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
      </Breadcrumb>
      {error && (
        <Alert variant="danger" className="row">
          {`${error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")}`}
        </Alert>
      )}

      <h2 className="my-4 text-center">Models</h2>
      {models ? (
        <InfiniteScroll
          dataLength={models.length} //This is important field to render the next data
          next={getMoreItems}
          hasMore={total > models.length}
          // loader={<Spinner accessibilityLabel="..." size="large" />}
          loader={""}
          endMessage={models.length ? "..." : ""}
        >
          <Table striped bordered hover size="sm" className="align-middle">
            <thead>
              <tr key="0" className="align-middle">
                {user.is_admin ? <th>ID</th> : ""}
                <th>Title</th>
                {user.is_admin ? <th>Prompt Fields</th> : ""}
                {user.is_admin ? <th>Completion Fields</th> : ""}
                <th>Status</th>
                <th>Created</th>
                <th>Modified</th>
                <th>Training Lines</th>
                <th>Versions</th>
                {user.is_admin ? <th>Users</th> : ""}
                {user.is_admin ? (
                  <th>
                    <Link to={`/models/model`}>
                      <Button variant="outline-success" size="sm" className="mx-1 my-1">
                        Add New ...
                      </Button>
                    </Link>
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {models.length
                ? models.map((item) => (
                    <tr key={item.id}>
                      {user.is_admin ? <td>{item.id}</td> : ""}
                      <td>
                        <Link to={`/models/model/${item.id}`}>{item.title}</Link>
                      </td>
                      {user.is_admin ? (
                        <td>
                          {(JSON.parse(item.prompt || "") || []).map((promptField) => (
                            <Badge pill bg="secondary" className="mx-1 my-1">
                              {promptField}
                            </Badge>
                          ))}
                        </td>
                      ) : (
                        ""
                      )}
                      {user.is_admin ? (
                        <td>
                          {(JSON.parse(item.completion || "") || []).map((completionField) => (
                            <Badge pill bg="secondary" className="mx-1 my-1">
                              {completionField}
                            </Badge>
                          ))}
                        </td>
                      ) : (
                        ""
                      )}
                      <td>
                        <Badge pill bg="secondary" className="mx-1 my-1">
                          {item.status}
                        </Badge>
                      </td>
                      <td>{item.created}</td>
                      <td>{item.modified}</td>
                      <td>{item.c_data}</td>
                      <td>{item.c_versions}</td>
                      {user.is_admin ? <td>{item.c_users}</td> : ""}
                      {user.is_admin ? (
                        <td>
                          <Button
                            id={item.id}
                            variant="outline-secondary"
                            size="sm"
                            className="mx-1 my-1"
                            onClick={(e) => copyModel(e.target.id)}
                          >
                            Clone
                          </Button>
                          <Link to={`/models/model/${item.id}`}>
                            <Button variant="outline-primary" size="sm" className="mx-1 my-1">
                              Edit
                            </Button>
                          </Link>
                          <Button
                            id={item.id}
                            variant="outline-danger"
                            size="sm"
                            onClick={(e) => handleDelShow(e.target.id)}
                            className="mx-1 my-1"
                          >
                            Remove
                          </Button>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        ""
      )}

      <Modal show={showDel} onHide={handleDelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove the Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to delete this Model. This is irreversible. Please type the word "DELETE" in the field to confirm.
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="DELETE"
              value={modelDelete}
              onChange={(e) => setModelDelete(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              removeModel(modelDelId);
              handleDelClose();
            }}
            disabled={modelDelete != "DELETE"}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
