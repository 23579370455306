import React, { useEffect, useReducer, useState } from 'react';
import { Link, useHistory, useLocation, useParams, seParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { modelsUrl } from '../../utils/api';
import { heightAdjust } from '../../utils/utils';

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModelData(token, modelId, modelDataId) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function createModelData(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateModelData(token, modelId, modelDataId, data) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delModelData(token, modelId, modelDataId) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function ModelData(props) {
    const history = useHistory();
    const location = useLocation();
    let { modelId, modelDataId: id } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [modelDataId, setModelDataId] = useState(id);
    const [modelTitle, setModelTitle] = useState('');
    const [modelPrompt, setModelPrompt] = useState([]);
    const [modelCompletion, setModelCompletion] = useState([]);
    const [modelData, setModelData] = useState({});
    const [modelDataPrompt, setModelDataPrompt] = useState([]);
    const [modelDataCompletion, setModelDataCompletion] = useState([]);
    const { settings, setSettings } = useSettings();
    const [error, setError] = useState(null);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if (modelId) {
            loadModel();
        }

        if (modelDataId) {
            loadModelData();
        }

        return () => {
            setModelTitle('');
            setModelPrompt([]);
            setModelCompletion([]);
            setModelData({});
            setModelDataPrompt([]);
            setModelDataCompletion([]);
        }
    }, [token]);

    const loadModel = () => {
        setError();
        getModel(token, modelId).then(r => {
            if (!r.model || !r.model.id) {
                console.log(r);
                setError(r);
            }
            setModelTitle(r.model.title);
            setModelPrompt(JSON.parse(r.model.prompt || []));
            setModelCompletion(JSON.parse(r.model.completion || []));
        });
    };

    const loadModelData = () => {
        setError();
        getModelData(token, modelId, modelDataId).then(r => {
            if (!r.modelData.id) {
                console.log(r);
                setError(r);
            }
            setModelDataPrompt(JSON.parse(r.modelData.prompt || []));
            setModelDataCompletion(JSON.parse(r.modelData.completion || []));
            setModelData(r.modelData);
        });
    };

    const onSubmit = async (redirect, createNew = false) => {
        setError();
        const data = {
            prompt: JSON.stringify(modelDataPrompt),
            completion: JSON.stringify(modelDataCompletion),
        };
        if (modelDataId) {
            await updateModelData(token, modelId, modelDataId, data);
            loadModelData();
        } else {
            await createModelData(token, modelId, data).then(r => {
                if (!r.modelDataId) {
                    console.log(r);
                    setError(r);
                }
                setModelDataId(r.modelDataId);
            });
        }
        if (redirect) {
            if (createNew) {
                history.push(`/models/model/${modelId}?action=createModelData`);
            } else {
                history.push(`/models/model/${modelId}`);
            }
        }
    };

    const removeModelData = (modelDataId) => {
        if (window.confirm("Do you really want to delete this data?")) {
            delModelData(token, modelId, modelDataId).then(r => {
                console.log(r);
                history.push(`/models/model/${modelId}`);
            });
        }
    };

    const modelDataTitle = modelDataId ? `Edit Data #${modelDataId}` : 'Add New Data';

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item href="#/models">Models</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/models/model/${modelId}`}>Model "{modelTitle}"</Breadcrumb.Item>
                <Breadcrumb.Item active>{modelDataTitle}</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <Alert variant="primary">
                <Form>
                    <h2 className="my-2 text-center">{modelDataTitle}</h2>
                    <fieldset>
                        <Row className="align-items-center" key={`modified`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Status</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label>{modelData.pending ?
                                    <Badge pill bg="success" className="mx-1 my-1">Pending</Badge> :
                                    <Badge pill bg="secondary" className="mx-1 my-1">Applied</Badge>}
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row className="align-items-center" key={`created`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Added</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label>{modelData.created ? new Date(modelData.created).toLocaleString('en-US') : ''}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="align-items-center" key={`modified`}>
                            <Col className="text-end" sm="2">
                                <Form.Label>Modified</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label>{modelData.modified ? new Date(modelData.modified).toLocaleString('en-US') : ''}</Form.Label>
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <legend>Prompt</legend>
                        {modelPrompt.map((prompt, index) =>
                            <Row className="align-items-center" key={`prompt-${index}`}>
                                <Col className="text-end" sm="2">
                                    <Form.Label>{prompt} *</Form.Label>
                                </Col>
                                <Col sm="10">
                                    <Form.Control
                                        as="textarea"
                                        placeholder={`Enter ${prompt}`}
                                        value={modelDataPrompt[index]}
                                        onChange={e => setModelDataPrompt(modelPrompt.map((v, i) => i == index ? e.target.value : modelDataPrompt[i]))}
                                        className="mb-2"
                                        onKeyUp={e => heightAdjust(e.target)}
                                        style={{ overflow: 'hidden', height: '100px' }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </fieldset>
                    <fieldset>
                        <legend>Completion</legend>
                        {modelCompletion.map((completion, index) =>
                            <Row className="align-items-center" key={`completion-${index}`}>
                                <Col className="text-end" sm="2">
                                    <Form.Label>{completion} *</Form.Label>
                                </Col>
                                <Col sm="10">
                                    <Form.Control
                                        as="textarea"
                                        placeholder={`Enter ${completion}`}
                                        value={modelDataCompletion[index]}
                                        onChange={e => setModelDataCompletion(modelCompletion.map((v, i) => i == index ? e.target.value : modelDataCompletion[i]))}
                                        className="mb-2"
                                        onKeyUp={e => heightAdjust(e.target)}
                                        style={{ overflow: 'hidden', height: '80px' }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </fieldset>
                    <div className="d-flex">
                        <Link to={`/models/model/${modelId}`}>
                            <Button
                                variant="secondary"
                                className="me-1 my-1"
                                size="sm"
                            >
                                &lt; Close
                            </Button>
                        </Link>
                        {modelDataId ? (
                            <Button
                                variant="danger"
                                className="me-1 my-1 ms-auto"
                                onClick={e => removeModelData(modelDataId)}
                                size="sm"
                            >
                                Remove
                            </Button>
                        ) : ''}
                        <Button
                            variant="primary"
                            className="me-1 my-1 ms-auto"
                            onClick={e => onSubmit(true, false)}
                            size="sm"
                        >
                            &lt; Save and Close
                        </Button>
                        <Button
                            variant="primary"
                            className="mx-1 my-1"
                            onClick={e => onSubmit(false, false)}
                            size="sm"
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="success"
                            className="ms-1 my-1"
                            onClick={e => onSubmit(true, true)}
                            size="sm"
                        >
                            Save and Add New &gt;
                        </Button>
                    </div>
                </Form>
            </Alert>
        </>);
}