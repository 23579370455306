import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Form, Modal, Table } from 'react-bootstrap';

import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';

import { pathsUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';

async function getPaths(token, limit, offset) {

    return fetch(`${pathsUrl}?limit=${limit || 0}&offset=${offset || 0}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function clonePath(token, pathId) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function delPath(token, pathId) {

    return fetch(`${pathsUrl}/${pathId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function Paths(props) {
    const history = useHistory();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [paths, setPaths] = useState([]);
    const { settings, setSettings } = useSettings();
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(null);

    const [showDel, setShowDel] = useState(false);
    const [pathDelete, setPathDelete] = useState('');
    const [pathDelId, setPathDelId] = useState();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get('action') == 'createPath') {
            history.push(`/paths/path`);
        }

        loadPaths(token, settings.items, offset);

        return () => setPaths([]);
    }, [user, token]);

    const handleDelClose = () => {
        setPathDelId();
        setPathDelete('');
        setShowDel(false);
    }
    const handleDelShow = (pathId) => {
        setShowDel(true);
        setPathDelete('');
        setPathDelId(pathId);
        console.log(pathId);
    }

    const loadPaths = (token, limit, offset) => {
        setError();
        getPaths(token, limit, offset).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setTotal(r.total || paths.length);
            setPaths(offset ? paths.concat(r.list || []) : (r.list || []));
        });
    }

    const getMoreItems = () => {
        loadPaths(token, settings.items, paths.length);
    }

    const copyPath = (pathId) => {
        clonePath(token, pathId).then(r => {
            console.log(r);
            if (r && r.pathId)
                loadPaths(token, settings.items, offset);
        });
    };

    const removePath = (pathId) => {
        delPath(token, pathId).then(r => {
            console.log(r);
            setPaths(paths.filter(item => item.id != pathId));
        });
    };

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Paths</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}

            <h2 className="my-4 text-center">Paths</h2>
            {paths ? (
                <InfiniteScroll
                    dataLength={paths.length} //This is important field to render the next data
                    next={getMoreItems}
                    hasMore={total > paths.length}
                    // loader={<Spinner accessibilityLabel="..." size="large" />}
                    loader={''}
                    endMessage={paths.length ?
                        ('...') :
                        ''
                    }
                >
                    <Table striped bordered hover size="sm" className="align-middle">
                        <thead>
                            <tr key="0" className="align-middle">
                                {user.is_admin ? (<th>ID</th>) : ''}
                                <th>Title</th>
                                <th>Created</th>
                                <th>Modified</th>
                                {user.is_admin ? (<th>Users</th>) : ''}
                                {user.is_admin ? (
                                    <th>
                                        <Link to={`/paths/path`}>
                                            <Button variant="outline-success" size="sm" className="mx-1 my-1">
                                                Add New ...
                                            </Button>
                                        </Link>
                                    </th>
                                ) : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {paths.length ? paths.map(item => (
                                <tr key={item.id}>
                                    {user.is_admin ? (<td>{item.id}</td>) : ''}
                                    <td>
                                        <Link to={`/paths/path/${item.id}`}>
                                            {item.title}
                                        </Link>
                                    </td>
                                    <td>{item.created}</td>
                                    <td>{item.modified}</td>
                                    {user.is_admin ? (<td>{item.c_users}</td>) : ''}
                                    {user.is_admin ? (
                                        <td>
                                            <Button id={item.id} variant="outline-secondary" size="sm" className="mx-1 my-1" onClick={e => copyPath(e.target.id)}>
                                                Clone
                                            </Button>
                                            <Link to={`/paths/path/${item.id}`}>
                                                <Button variant="outline-primary" size="sm" className="mx-1 my-1">
                                                    Edit
                                                </Button>
                                            </Link>
                                            <Button id={item.id} variant="outline-danger" size="sm" onClick={e => handleDelShow(e.target.id)} className="mx-1 my-1">
                                                Remove
                                            </Button>
                                            <Modal show={showDel} onHide={handleDelClose} centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Remove the Path</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    You want to delete this Path. This is irreversible. Please type the word "DELETE" in the field to confirm.
                                                    <Form.Group className="mb-2">
                                                        <Form.Control type="text" placeholder="DELETE" value={pathDelete} onChange={e => setPathDelete(e.target.value)} />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={e => {
                                                        removePath(pathDelId);
                                                        handleDelClose();
                                                    }} disabled={pathDelete != 'DELETE'}>
                                                        OK
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    ) : ''}
                                </tr>
                            )) : ''}
                        </tbody>
                    </Table>
                </InfiniteScroll>
            ) : ''}
        </>);
}