import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom'
import { Alert, Button, Container, Form, Row } from 'react-bootstrap';

import { loginUrl, removeParams } from '../../utils/api';

function loginUser(credentials) {
    return fetch(loginUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials),
    })
        .then(data => data.json());
}

export default function Login({ setToken, setUser }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [show, setShow] = useState(true);

    const customerEmail = new URLSearchParams(window.location.search).get("c");

    const handleSubmit = e => {
        setError();
        e.preventDefault();
        loginUser({
            email: customerEmail ? customerEmail : email,
            password
        }).then(r => {
            if (!r.auth) {
                console.log(r);
                setError(r);
            }
            window.location = removeParams('c');
            setUser(r.user || null);
            setToken(r.auth || null);
        });
    }

    return (
        <>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <h2 className="my-4 text-center">Login</h2>
            {customerEmail && show ? (
                <Alert variant="info" className="row" dismissible onClose={() => setShow(false)}>
                    Welcome, and Congratulations for setting up your Strietzel.AI account!
                    <br />
                    Enter your password one more time below to securely log into your dashboard for the first time.
                </Alert>
            ) : ''}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3 row">
                    <Form.Label disabled={!!customerEmail} >Email *</Form.Label>
                    {customerEmail ? (
                        <Form.Control type="text" name="email" disabled value={customerEmail} />
                    ) : (
                        <Form.Control type="text" name="email" onChange={e => setEmail(e.target.value)} />
                    )}
                </Form.Group>
                <Form.Group className="mb-3 pb-3 row">
                    <Form.Label>Password *</Form.Label>
                    <Form.Control type="password" name="password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3 pt-3 row">
                    <Button type="submit">Sign in</Button>
                </Form.Group>
                <Form.Group className="mb-3 row">
                    <Link role="button" className="btn btn-success" to='/register'>Sign up ...</Link>
                </Form.Group>
            </Form>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
};